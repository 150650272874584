@use "../utilities/settings" as *;

.matchtest__header {
	min-height: 300px;
	max-height: 500px;
	height: 30vh;
	
	.header-image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		right: 0;
		bottom: 0;
		left: 0;
		top: 0;
		object-position: 50% 33%;
	}
	
	.gradient {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, .9) 50%, rgba(white, 0.5) 100%);
		
		@media (min-width: $breakpoint-md) {
			width: 80%;
			background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, .9) 50%, rgba(white, 0) 100%);
		}
	}
	
	.breadcrumbs {
		padding-top: 0;
		margin-left: -15px;
		margin-bottom: 30px;
	}
}


.matchtest {
	background: $lichtbruin;
	padding-bottom: 100px;
	
	@media (min-width: $breakpoint-md) {
		padding-bottom: 300px;
	}
	
	.boom {
		width: 308px;
		bottom: 0;
		right: calc(50% - 450px);
		
		@media (min-width: $breakpoint-lg) {
			right: calc(50% - 650px);
		}
	}
}

.matchtest__question {
	background: white;
	padding: 85px 20px 35px;
	position: relative;
	display: none;
	
	@media (min-width: $breakpoint-md) {
		padding: 100px 100px 50px 100px;
	}
	
	&.active {
		display: flex;
	}
}

.matchtest__current-answer {
	background: $lichtpaars;
	border-radius: 50%;
	color: white;
	font-weight: 700;
	height: 55px;
	position: absolute;
	right: 15px;
	top: 15px;
	width: 55px;
	
	@media (min-width: $breakpoint-md) {
		height: 76px;
		width: 76px;
	}
}

.matchtest__answers {
	margin: 30px 0;
	width: 75%;
	
	.formbox {
		align-items: center;
		display: flex;
		width: 100%;
		
		label {
			background: $lichtgrijs;
			height: 100%;
			justify-content: center;
			margin: 7.5px 0;
			padding: 18px 15px;
			position: relative;
			width: 100%;
			border-radius: 5px;
			cursor: pointer;
			
			&::before {
				content: "\f111";
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-size: 95%;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				font-weight: 400;
				margin-right: 5px;
				position: relative;
				text-align: right;
				text-rendering: auto;
				color: inherit;
				padding-right: 15px;
			}
		}
		
		input {
			display: none;
			
			&:checked ~ label {
				background: $groen;
				color: white;
				
				&::before {
					content: "\f058";
					font-weight: 900;
				}
			}
		}
	}
}

.matchtest__btn {
	align-self: center;
}

.matchtest__standpunt {
	display: none;
}