@use "../utilities/settings" as *;

.content__header {
    width: 100%;
    height: 50vh;
    max-height: 400px;
    overflow: hidden;
    border-radius: 0 0 150px 0 / 0 0 150px 0;
    position: relative;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}