@use "../utilities/settings" as *;

.header {
	background-color: white;
	box-shadow: 0 1px 14px 0 rgba(black, 0.07);
	overflow-x: hidden;
	position: relative;
	z-index: 999;

	* {
		color: $groen;
	}

	&.header__top-bar {
		height: 44px;
		width: 100%;
		line-height: 44px;
		overflow-y: hidden;
		
		.nav {
			
			a {
				font-size: 12px;
			}
		}
	}
	
	ul {
		align-items: center;
		display: flex;
		justify-content: flex-start;
		list-style: none;
	}
	
	.nav {
		margin-left: auto;
		flex: 0 0 100%;
		justify-content: flex-end;
		
		ul li {
			padding: 0 10px;
			white-space: nowrap;

			&.paars_mobiel {
				a {
					@media (max-width: $breakpoint-lg) {
						color: #42325e !important;
					}
				}
			}
		}


	}
	
	.logo {
		width: 131px;
		height: 48.39px;
		
		img {
			width: 131px;
			height: 48.39px;
		}
	}
	
	&.header__main {
		height: 72px;
		position: sticky;
		top: 0px;
		z-index: 999;
		
		.header__inner {
			align-items: center;
			display: flex;
			height: 100%;
			width: 100%;
			
			.nav {
				align-items: center;
				background-color: white;
				-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
				box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
				flex: 1 0 0;
				flex-direction: column;
				height: 100vh;
				justify-content: center;
				left: -100%;
				overflow-y: scroll;
				padding: 50px 0 0;
				position: fixed;
				top: 0px;
				transition: left 0.2s ease-in-out;
				width: 80%;
				z-index: 20;
				
				@media (min-width: $breakpoint-lg) {
					box-shadow: none;
					display: flex;
					height: auto;
					left: auto;
					overflow-y: visible;
					padding: 0;
					position: static;
					width: auto;
				}
				
				ul {
					flex-flow: column wrap;
					
					@media (min-width: $breakpoint-lg) {
						flex-flow: row nowrap;
						margin-left: auto;
					}
					
					li {
						width: 100%;
						
						@media (min-width: $breakpoint-lg) {
							width: auto;
						}
					}
				}
				
				.nav__close {
					// background-color: rgba(0, 0, 0, 0.05);
					color: #666;
					font-size: 1em;
					height: 50px;
					line-height: 50px;
					position: absolute;
					right: 0;
					text-align: center;
					top: 0;
					width: 50px;
					
					@media(min-width: $breakpoint-lg) {
						display: none;
					}
				}
				
				.nav__overlay {
					display: none;
					height: 100%;
					left: 80%;
					position: fixed;
					top: 0;
					transition: .2s ease-out;
					width: 20%;
					z-index: 9;
					
					@media(min-width: $breakpoint-lg) {
						display: none;
					}
				}
				
				&.active {
					left: 0%;
					
					.nav__overlay, .nav__close {
						display: block;
						font-size: 1.5em;
						line-height: 50px;
						text-align: center;
						top: 0;
					}
					
					.nav__overlay {
						background: black;
						opacity: .5;
						position: fixed;
						transition: opacity .2s ease-out;
					}
					
					.nav__close {
						background: white;
						height: 50px;
						position: absolute;
						right: 0;
						width: 50px;
					}
				}
				
				a {
					border-top: 1px solid rgba($groen, .15);
					display: block;
					font-family: $button-font;
					font-size: 15px;
					font-weight: 400;
					letter-spacing: 0.03em;
					line-height: 80px;
					padding: 0 25px;
					text-align: left;
					width: 100%;
					
					@media (min-width: $breakpoint-lg) {
						border: 0;
						line-height: 1;
						padding: 0;
					}
				}
				
				.btn {
					border-radius: 0;
					height: 80px;
					
					a {
						color: white;
						line-height: 80px;
					}
					
					@media (min-width: $breakpoint-lg) {
						border-radius: 17px;
						height: 34px;
						line-height: 30px;
						margin: 0 5px;
						padding: 0 10px;
						
						a {
							font-size: 13px;
							line-height: 34px;
						}
						
						&.btn-primary {
							margin-right: 0;
						}
					}
				}
				
			}
			
			.btn-menu {
				color: $groen;
				display: block;
				height: 53px;
				line-height: 53px;
				margin-left: 5px;
				padding: 0 0 0 25px;
				text-align: center;
				margin-left: auto;
				cursor: pointer;
				
				.fa {
					font-size: 30px;
					line-height: 53px;
				}
				
				@media(min-width: $breakpoint-lg) {
					display: none;
				}
			}

			
		}
	}
}

.small-header {
	background-color: $groen;
	min-height: 265px;
	
	h1, p, span {
		color: white;
	}
	
	.hoek-golf {
		bottom: -1px;
		height: 100px;
		position: absolute;
		right: -1px;
		width: 200px;
	}
	
	.breadcrumbs {
		margin-bottom: 30px;
		margin-left: -15px;
		padding-top: 0;
		
		#breadcrumbs * {
			color: white;
		}
	}
}

.header__search-button {
	padding: 15px;
	margin-right: 15px;
}

#menu-header-top-menu-mobiel {
	padding-bottom: 130px;
}

