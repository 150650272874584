@use "../utilities/settings" as *;

.btn {
	appearance: none;
	background: none;
	border-radius: 30px;
	color: white !important;
	cursor: pointer;
	display: inline-block;
	font-family: $button-font;
	font-size: 18px;
	height: 55px;
	line-height: 55px; /* 55px min border: 2 x 4px */
	max-width: 100%;
	padding: 0 25px;
	text-align: center;
	text-decoration: none;
	transition: all .2s linear;
	white-space: nowrap;
	letter-spacing: 0.03em;
	// overflow: hidden;
	
	@media (min-width: $breakpoint-sm) {
		align-self: flex-start;
	}

	@media(min-width: $breakpoint-lg) {
		padding: 0px 35px;

		&.btn-hover {
			overflow: hidden;
		}

		&.btn-hover:hover {
			padding: 0px 50px;
			width: auto !important;
		}
	}
	
	&:hover {
		color: white!important;
		text-decoration: none;
		opacity: 1;
		
		a {
			text-decoration: none;
			opacity: 1;
		}
	}
	
	&:disabled,
	&:disabled:hover {
		background: rgba($grijs, .5) !important;
		color: white !important;
		cursor: not-allowed;
	}
	
	&.btn-primary {
		background: $groen;
		
		&:hover {
			background: lighten($groen, 10%);
		}
		
		&.btn-transparent {
			color: $groen !important;
			border: 4px solid $groen;
			line-height: 47px;
			background: none;
			
			&:hover {
				color: white !important;
				background: $groen;
			}
		}
	}
	
	&.btn-secondary {
		background: $paars;
		
		&:hover {
			background: lighten($paars, 15%);
		}
		
		&.btn-transparent {
			color: $paars !important;
			border: 4px solid $paars;
			line-height: 47px;
			background: none;
			
			&:hover {
				color: white !important;
				background: $paars;
			}
		}
	}
	
	.fas, .far {
		color: white;
		font-size: inherit;
		line-height: inherit;
		margin-right: 5px;
		position: relative;
	}
	
	&.-disabled {
		cursor: not-allowed;
		opacity: .5;
		background: #666;
		
		&:hover {
			background: #666;
		}
	}
}

button {
	appearance: none;
	background: transparent;
	border: 0;
	cursor: pointer;
}

.buttons {
	padding: 25px 0;
	
	.btn {
		margin: 7.5px 0;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			width: auto;
		}
	}
}