@use "../../utilities/settings" as *;

.section-opsomming {
	padding-bottom: 75px;
	background-color: $lichtbruin;
	
	@media (min-width: $breakpoint-md) {
		padding-bottom: 125px;
	}
	
	.animatie-wrapper .hond {
		left: auto;
		right: 5%;
		transform: scaleX(-1);
	}
}

.opsomming-list {
	list-style: none;
}

.opsomming-list-item {
	margin-top: 30px;
	margin-bottom: 30px;
	
	&:nth-child(1),
	&:nth-child(6),
	&:nth-child(10),
	&:nth-child(14) {
		> .nummer {
			color: $groen;
		}
	}
	
	&:nth-child(2),
	&:nth-child(5),
	&:nth-child(9),
	&:nth-child(13){
		> .nummer {
			color: $oranje;
		}
	}
	
	&:nth-child(3),
	&:nth-child(8), 
	&:nth-child(12),
	&:nth-child(16){
		> .nummer {
			color: $blauw;
		}
	}
	
	&:nth-child(4),
	&:nth-child(7),
	&:nth-child(11),
	&:nth-child(15)
	 {
		> .nummer {
			color: $geel;
		}
	}
	
	> .nummer {
		font-size: 90px;
		line-height: 50px;
		font-family: 'koara_bold', sans-serif;
	}
	
	> .titel {
		margin: 0 0 1em 0;
	}
	
	> .tekst {}
}
