@use "../utilities/settings" as *;

.card {
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
	display: flex;
	flex-flow: column wrap;
	overflow: hidden;
	height: 100%;
	
	figure {
		padding-top: 75%;
	}
	
	.card__content {
		flex-grow: 1;
		padding: 30px;
		position: relative;
		
		&.card__content--bruin {
			background-color: $lichtbruin;
		}
		
		&.card__content--groen {
			background-color: $lichtgroen;
		}
		
		&.card__content--paars {
			background-color: $lichtpaars;
		}

		&.card__content--blauw {
			background-color: #EEF2F8;
		}
		
		&.card__content--lichtpaars {
			background-color: rgba($lichtpaars, .5);
		}
	}
	
	.card__date, .card__title {
		color: $zwart;
		font-family: $button-font;
		font-weight: 400;
	}
	
	.card__date {
		color: $paars;
	}
	
	&.card--post {
		position: relative;
		
		figure {
			border-radius: 60px 60px 60px 0;
		}
		
		.card__meta {
			font-size: 13px;
		}
		
		.card__content {
			display: flex;
			flex-flow: column wrap;
			padding: 40px 0;
			position: static;
			width: 100%;
		}
		
		.card__date {
			font-family: Roboto, sans-serif;
			font-size: 1em;
			font-weight: 400;
		}
		
		sep {
			font-weight: 700;
			padding: 0 2.5px;
		}
		
		.card__author {
			color: $paars;
			font-size: 1em;
			font-weight: 700;
		}
		
		.card__title {
			color: $paars;
			font-family: $heading-font;
			font-size: 1.56em;
			margin: 25px 0;
		}
		
		.card__excerpt {
			hyphens: auto;
			line-height: 1.7;
			margin-bottom: 25px;
			width: 100%;
			word-break: break-word;
			
			p {
				line-height: 1.7;
			}
		}
		
		a.btn {
			margin-top: auto;
			
			&::after {
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}


section.section-eenvoudige-cards {
	.h2-small {
		margin-bottom: 30px;
	}
	
	.card {
		.card__title {
			margin-bottom: 0;
			color: $groen;
		}
		
		p {
			margin-top: 15px;
		}
		
		&:hover {
			.card__title {
				color: white;
			}
			
			.card__content {
				background: $lichtpaars;
			}
		}
	}
}

.post-type-archive-elke-dag-iets-leuks .col__card {
	margin-bottom: 30px;
}