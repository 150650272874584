@use "../utilities/settings" as *;

.single-villa, .page-villa-doomijn {
    // .locatie__header {
    //     margin-bottom: 0;
    // }
    h1 {
        color: white;
    }
	
	section:not(.footer) h2,
	section:not(.footer) h3{
        color: $rood !important;
    }
	
    section.blog-magazines h2 {
        color: $rood;
    }

    .locatie__header {
        background-color: $rood;
    }

    .locatie__intro p {
        color: black;
    }

    .search-location form .checkboxes {
        color: $rood;

        .checkboxes_label {
            color: $rood;
        }

        .checkbox {
            color: $rood;

            &:hover {
                // background-color: lighten($rood, 60%);

                // &::before {
                //     color: lighten($rood, 30%);
                // }
            }
        }

        .checkbox input:checked ~ label {
            background-color: $rood;
            color: white;
        }
    }

    footer {
        background-color: $paars;

        .footer__golf {
            background-image: url('../img/golf-shape-footer-paars.svg');
        }

        h3 {
            color: white;
        }
	    
	    .footer__col hr.footer__line {
		    border-top: 2px solid darken($paars, 15%);
	    }
    }

    .btn-primary, .btn-secondary:not(.menu-item) {
        background-color: $rood;

        &:hover {
            background-color: darken($rood, 10%);
        }
    }
    .slick-arrow {
        background-color: $oranje;
    }
	
	.locatie-slider__item .cats .cat {
		background: $oranje;
	}
	
	section.quote,
	{
		background: $rood;
	}
	
	.text-2col-golf,
	section.text-image-golf,
	.text-button {
		background: $roze;
	}
	
	.section.blog-magazines__related {
		background-image: url(../img/related-villa.svg);
		
		.card-blog__content .read-more {
			color: $zwart;
		}
	}
	
	.section.blog-magazines .card-blog__content .read-more {
		color: $zwart;
	}
}