@use "../utilities/settings" as *;

section.text-image__round__hidden {
	&.large-padding {
		padding-bottom: 50px;
		margin-bottom: 150px;
	}
	
	.col-lg-6 {
		position: static;
	}
    
	
	.image-placeholder {
		width: 100%;
		height: 0;
		padding-top: 75%;
		display: none;
		
		@media(min-width: $breakpoint-lg) {
			display: block;
		}
	}
	
	.text-image__image--container {
		position: relative;
		margin-top: 50px;
		
		.text-image__image {
			width: 100%;
			height: auto;
			display: block;
			border-radius: 40px;
		}
		
		@media(min-width: $breakpoint-lg) {
			position: absolute;
			top: 50px;
			right: 0;
			height: calc(100% - 100px);
			width: calc(50% - 50px);
			margin-top: 0;
			
			.text-image__image {
				height: 100%;
				object-fit: cover;
				position: absolute;
				top: 0px;
				left: 0;
				border-radius: 17% 0px 0px 17% / 50% 0 0 50%;
			}
			
			.boom {
				width: 300px;
				height: 300px;
				left: -60px;
			}
		}
	}

	&.text-image__round--blog {
		
		&.-first {
			z-index: 1
		}
		
		&.-second {
			z-index: 0;
			overflow: hidden;
		}
		
		.text-image__image--container {
			&.-left {
				@media (min-width: $breakpoint-lg) {
					position: absolute;
					top: 50px;
					right: auto;
					left: 0;
					height: calc(100% - 50px);
					width: calc(50% - 50px);
					
					.text-image__image {
						border-radius: 0 120px 0 0;
					}
				}
            }
		}
		
		.text-image__image--container {
			&.-right {
				margin-top: -25px;
                overflow: hidden;
				
				.text-image__image {
					margin-bottom: 25px;
				}

                .tekening {
                    position: absolute;
                    bottom: 25px;
                    left: 32px;
                    width: 100px;
                }
				
				@media (min-width: $breakpoint-lg) {
					position: absolute;
					margin-top: 50px;
					top: 0px;
					left: auto;
					right: 0;
					height: 100%;
					width: calc(50% - 20px);
					
					.text-image__image {
                        border-radius: 120px 0 0 0;
						margin-bottom: 0;
					}

                    .tekening {
                        position: absolute;
                        bottom: 50px;
                        width: 150px;
                    }
                }
			}
		}
		
	}
}