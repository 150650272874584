@use "../utilities/settings" as *;

.section-tijdlijn {
	padding-bottom: 75px;
	background-color: $lichtbruin;
	
	@media (min-width: $breakpoint-md) {
		padding-bottom: 125px;
	}
	
	.animatie-wrapper .hond {
		left: auto;
		right: 5%;
		transform: scaleX(-1);
	}

    .tijdlijn-list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .tijdlijn-content {
        display: none;
    }

    .tijdlijn-content.active {
        display: block !important;
    }

    .tijdlijn-content {
        p {
            margin-bottom: 0;
        }
    }

    .tijdlijn-list-item.active {
        background-color: #c8dcd1;
    }

    .tijdlijn-list-item {
        display: flex;
        position: relative;
        align-items: center;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 8px;
        background-color: #fff;
        transition: background-color 0.3s;
    }

    .tijdlijn-list-item:hover {
        background-color: #c8dcd1;
    }

    .line {
        position: absolute;
        width: 5px;
        background-color: #4e643c;
        left: 28px;
        top: 100%;
        height: 20px;
    }

    .nummer-col {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: #42325e;
    }

    .nummer {
        font-size: 45px;
        margin-bottom: 0;
        margin-right: 20px;
        line-height: 50px;
        color: #232323;
        font-family: 'koara_bold', sans-serif;
    }

    .content-col {
        display: flex;
        align-items: center;
        padding-left: 1rem;
    }

    .titel {
        margin-top: 0px !important;
        font-size: 15px;
        color: #232323;

        &.side {
            font-size: 18px;
            margin-top: 10px !important;
        }
    }

    p {
        margin-bottom: 40px;
    }

    .tekst {
        margin: 0;
        font-size: inherit;
        margin-top: 15px;
    }

    @media (max-width: 768px) {

        .content-col {
            padding-left: 0;
        }
    }
}