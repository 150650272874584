@use "../../utilities/settings" as *;

.groene-achtergrond {
	>.bg-color {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: $groen;
	}
	
	* {
		color:white;
	}
	
	.bomen {
		position: absolute;
		left: 0px;
		bottom: 0;
		height: 200px;
		width: auto;
		
		@media (min-width: $breakpoint-xxl) {
			height: 300px;
		}
		
		@media (min-width: 1600px) {
			height: 400px;
		}
	}
	
	.animatie-lijn .alien {
		@media (min-width: $breakpoint-md) {
			bottom: 0px;
			width: 125px;
			right: -30px;
		}
		
		@media (min-width: $breakpoint-xl) {
			width: 250px;
			right: -60px;
		}
	}
}