@use "../utilities/settings" as *;

section.quote {
	padding: 100px 0;
	background-color: $groen;
	min-height: 240px;
	
	@media (min-width: $breakpoint-md)  {
		min-height: 400px;
	}
	
	blockquote {
		color: white;
		text-align: center;
		position: relative;
		z-index: 1;
		font-size: 2.14em;
		margin: 0;
		padding-left: 0;
		
		&::before {
			display: none;
		}
	}
	
	.bomen {
		left: 0;
		bottom: 0;
		height: 215px;
		
		@media (min-width: $breakpoint-md) {
			height: 360px;
		}
	}

	.quote-name {
		margin-top: 15px;
	}
}