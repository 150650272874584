@use "../utilities/settings" as *;

.ontdek-doomijn {
	background: white;
	border-radius: 30px 0 0 30px;
	bottom: 15vh;
	z-index: 99;
	height: 60px;
	padding: 0 20px;
	position: fixed;
	right: -80px;
	transition: transform .2s ease-out;
	box-shadow: -3px 1px 12px 1px rgba($zwart, .1);
	
	@media (min-width: $breakpoint-md) {
		padding: 0 40px 0 20px;
		border-radius: 40px 0 0 40px;
		right: -90px;
		height: 80px;
		transform: translateX(20px);
	}
	
	&:hover {
		text-decoration: none;
		transform: translateX(-75px);
		transition: transform .2s ease-out;
	}
	
	img {
		height: 23px;
		margin-right: 15px;
		
		@media (min-width: $breakpoint-md) {
			height: 44px;
		}
	}
}