@use "../utilities/settings" as *;

.animatie-lijn {
    width: 100%;
    height: 0px;
	// background-color: blue;
    z-index: 5;
    position: relative;

    .bij {
        position: absolute;
		//bottom: 250px;
		bottom: 0;
		height: 100px;
		left: calc(50% - 224px);
		width: 100px;
		z-index: 2;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% - 134px);
            width: 150px;
            height: 150px;
		}
		
		@media (min-width: $breakpoint-lg) {
			bottom: -50px;
			left: calc(50% - 350px);
		}
	}

    .planten {
		position: absolute;
		bottom: -1px;
		left: 10px;
		width: 150px;
		height: 100px;

		.plant-animatie {
			width: 150px;
			height: 150px;
			position: absolute;
			bottom: -5px;
			left: -40px;
		}

		.bosje {
			position: absolute;
			left: 0px;
			bottom: 0;
			width: 100%;
			z-index: 2;
		}

		@media(min-width: $breakpoint-lg) {
			width: 250px;
			height: 200px;
			left: 50%;

			.plant-animatie{
				width: 200px;
				height: 200px;
			}
		}
	}

    .alien {
		bottom: -100px;
		right: -30px;
		width: 100px;
		height: 100px;
		z-index: 1;
		transform: rotate(-40deg);
        position: absolute;
		
		@media (min-width: $breakpoint-md) {
			bottom: 0;
			width: 250px;
			height: 250px;
			right: -60px;
		}
	}

    .boompje {
		bottom: -4px;
		left: -60px;
		width: 130px;
		height: 130px;
		z-index: -1;
		
		@media (min-width: $breakpoint-lg) {
			left: 15px;
			width: 175px;
			height: 175px;
		}

		@media(min-width: $breakpoint-xl) {
			width: 230px;
			height: 230px;
		}
		
		@media (min-width: 1360px) {
			z-index: 0;
		}
	}

	.slang {
		position: absolute;
		left: 0;
		top: -75px;
		width: 150px;
		height: 150px;
		
		@media (min-width: $breakpoint-xl) {
			width: 330px;
			height: 330px;
			top: -165px;
		}
	}
}

.animatie-wrapper {
	height: 100%;
	
	.beer {
        position: absolute;
        max-width: 200px;
        max-height: 150px;
		width: auto;
		height: 25%;
		bottom: -1px;
        transform: scaleX(-1);
            right: 0;
	        left: auto;
		
        @media(min-width: $breakpoint-sm) {
        }

        @media(min-width: $breakpoint-lg) {
	        height: 100%;
	        bottom: -3px;
	        width: 100%;
            max-width: 300px;
            max-height: 250px;
        }

        @media(min-width: 2075px) {
	        transform: scaleX(1);
            left: 5%;
            max-width: 400px;
            max-height: 300px;
	        right: auto;
        }
    }

    .hond {
        position: absolute;
        bottom: 0;
        left: 0%;
        width: 100px;
        height: 100px;

        @media(min-width: $breakpoint-xl) {
            left: 15%;
            width: 180px;
            height: 180px;
        }
    }
}