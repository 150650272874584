@use "../utilities/settings" as *;

.page-blog-magazines {
	.checkboxes {
		background: $groen;
		border-radius: 24px;
		display: table;
		margin: 25px auto;
		padding: 0;
		position: relative;
		text-align: center;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			border-radius: 25px;
			padding: 2px 20px;
			width: auto;
			margin: 0;
			float: right;
		}
		
		.checkboxes_label {
			background-color: $groen;
			border-radius: 25px;
			color: white;
			cursor: pointer;
			display: block;
			display: flex;
			font-family: $button-font;
			font-size: 14px;
			padding: 15px 20px;
			position: relative;
			width: 100%;
			
			&::after {
				color: inherit;
				content: "\f107";
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-size: 95%;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				font-weight: 900;
				margin-left: auto;
				position: relative;
				text-align: right;
				text-rendering: auto;
				top: 3px;
			}
			
			@media(min-width: $breakpoint-lg) {
				display: none;
			}
		}
		
		&.active .checkboxes_label {
			border-radius: 25px 25px 0px 0px;
			
			&::after {
				content: "\f106";
			}
		}
		
		.checkboxes__filter-button {
			background: $lichtgrijs;
			color: $zwart;
			position: absolute;
		}
		
		.checkboxes__inner {
			border-radius: 0px 0px 25px 25px;
			display: none;
			overflow: hidden;
			//position: absolute;
			top: 100%;
			width: 100%;
			z-index: 2;
			
			@media(min-width: $breakpoint-lg) {
				display: block;
				margin: 0px auto;
				position: relative;
				top: 0;
				width: auto;
			}
		}
		
		&.active .checkboxes__inner {
			display: block;
		}
		
		.checkbox {
			cursor: pointer;
			display: block;
			width: 100%;
			
			label {
				color: white;
				display: inline-block;
				font-family: $button-font;
				font-size: 14px;
				letter-spacing: 0.03em;
				margin: 0;
				padding: 15px 15px;

				@media(min-width: $breakpoint-lg) {
					font-size: 10px;
				}

				@media(min-width: $breakpoint-xl) {
					font-size: 14px;
				}
			}
			
			@media(min-width: $breakpoint-lg) {
				display: inline-block;
				width: auto;
				
				label {
					border-radius: 25px;
				}
			}
		}
		
		.checkbox input {
			display: none;
		}
		
		.checkbox input ~ label {
			background-color: $groen;
			color: white;
			
			&::before {
				color: inherit;
				content: "\f111";
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-size: 95%;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				font-weight: 900;
				margin-right: 5px;
				position: relative;
				text-align: right;
				text-rendering: auto;
			}
		}
		
		.checkbox input:checked ~ label {
			background-color: $groen;
			color: white;
			
			&::before {
				color: inherit;
				content: "\f058";
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-size: 95%;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				font-weight: 900;
				margin-right: 5px;
				position: relative;
				text-align: right;
				text-rendering: auto;
			}
		}
	}

	.blogs {
		background-color: #f7f7f7;
	}
}

.magazines-slider {
	margin-top: 30px;
	
	.pauw {
		bottom: 0;
		left: 20px;
		height: 75%;
		
		&.pauw--klein {
			height: 33%;
		}
		
		@media (min-width: $breakpoint-lg) {
			left: calc(100% - 125px);
			width: 215px;
			height: auto;
		}
	}
	
	.buttons {
		margin-top: auto;
		padding: 0;
		
		@media (min-width: $breakpoint-lg) {
			margin-top: 0;
		}
		
		.btn {
			margin: 10px 10px 0 0;
		}
	}
	
	ul.slick-dots {
		display: flex;
		justify-content: center;
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;
		margin-top: 25px;
		
		@media (min-width: $breakpoint-md) {
			justify-content: flex-start;
			padding-top: 2rem;
			margin-top: 0;
		}
		
		li {
			margin: 0 .5rem;
			position: relative;
			
			@media (min-width: $breakpoint-md) {
				left: 25%;
				transform: translateX(-50%);
			}
			
			button {
				background-color: rgba($lichtgrijs, .75);
				border: none;
				border-radius: 100%;
				display: block;
				height: 1rem;
				padding: 0;
				text-indent: -9999px;
				width: 1rem;
				
				&:focus, &:active {
					border: 0;
					outline: 0;
				}
			}
			
			&.slick-active {
				button {
					background-color: $groen;
				}
			}
		}
	}
}

.magazines-slide {
	
	figure {
		
		@media (min-width: $breakpoint-sm) {
			border-radius: 60px 60px 60px 0;
			width: auto;
		}
		
		@media (min-width: $breakpoint-lg) {
			height: 100%;
		}
	}
	
	.card__title {
		font-family: $heading-font;
	}
	
	.magazines-slide__content {
		flex: 1;
		padding: 15px 0;
	}
	
}

.regenboog {
	right: -60px;
	bottom: -10px;
	width: 120px;
	height: 120px;
	
	@media (min-width: $breakpoint-md) {
		width: 230px;
		height: 230px;
		right: -100px;
		bottom: -40px;
	}
}

/*= Pagination loader */

#pagination-loader {
	width: 40px;
	height: 40px;
	margin: 1.5em auto;
	border-radius: 50%;
	animation: pulse .5s infinite alternate;
	background: $groen;
	transform-origin: center center;
}

@-webkit-keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1.4);
	}
	
	to {
		opacity: 0.5;
		transform: scale(.6);
	}
}

@keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1.3);
	}
	
	to {
		opacity: 0.5;
		transform: scale(.6);
	}
}

.ajax-overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	display: none;
	background: rgba($grijs, .3);
	align-items: center;
	justify-content: center;
}