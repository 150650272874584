@use "../utilities/settings" as *;
@use "../utilities/mixins" as *;

.post-type-archive-locaties {
	.h2-small {
		text-align: center;
	}
}

#app {
    width: 100%;
    height: 100%;
}

.map {
    background-color: white;
    width: 100%;
    position: relative;
    padding: 25px;

    #map {
        position: relative;
        width: calc(100% + 50px);
        height: 90vh;
        z-index: 0;
        background-color: #ccc;
        margin: -25px;
        margin-bottom: 0;

        @media(min-width: $breakpoint-lg) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            margin: 0;
            width: 100%;
        }
    }

    .marker {
        // padding: 5px;
        border-radius: 3px;
        background-color: $groen;
        font-weight: bold;
        color: white;
        cursor: pointer;
        width: 40px;
        height: 30px;
        z-index: 1;

        &.active {
            background-color: $paars;

            .marker__name {
                background-color: $paars;
            }

            &::after {
                background-color: $paars;
            }
        }

        .marker__rails {
            border-radius: 3px;
            overflow: hidden;
            width: 40px;
            height: 30px;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .marker__name {
            color: white;
            width: 40px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: $groen;

            &:hover {
                background-color: lighten($groen, 10%);
            }
        }

        &:hover {
            z-index: 2;
            .marker__rails {
                // display: block;
                width: 300px;
            }

            .circle {
                display: none;
            }

            &::after {
                background-color: lighten($groen, 10%);
            }
        }

        &::after {
            position: absolute;
            bottom: -2.5px;
            left: 50%;
            transform: translate(-50%, 0) rotate(45deg);
            width: 5px;
            height: 5px;
            content: "";
            background-color: $groen;
        }

        .circle {
            position: absolute;
            bottom: calc(100% - 6px);
            right: 5px;
            width: 12px;
            height: 12px;
            font-size: 8px;
            line-height: 12px;
            text-align: center;
            color: white;
            border-radius: 50%;
            background-color: $oranje;
        }
    }

    .cats {
        width: 100%;
        display: table;
        margin-top: 15px;

        .cat {
            display: inline-block;
            padding: 8px 12px;
            background-color: $groen;
            border-radius: 10px 10px 0px 10px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin-right: 5px;

            &:hover {
                background-color: lighten($groen, 10%);
            }
        }
    }

    .list {
        width: calc(100% + 50px);
        margin: 0px -25px;
        padding: 0px;
        background-color: white;
        position: relative;
        z-index: 2;
        overflow: hidden;

        .list-count {
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 0.7em;
            color: #ccc;
        }

        &:first-child {
            padding-top: 35px;
        }

        h2 {
            padding: 25px 35px;
            padding-bottom: 0;
        }

        .list-item {
            padding: 25px;
            display: table;
            width: 100%;
            border-bottom: 1px solid rgba(0,0,0,0.05);
            text-decoration: none;
            cursor: pointer;
            
            .btn {
                display: none;
                margin-top: 25px;
            }

            @media(min-width: $breakpoint-lg) {
                padding: 25px 0px;
            }

            &.highlight {
                background-color: rgba($groen, 0.2);

                .btn {
                    display: block;
                }
            }

            &:hover {
                background-color: rgba(0,0,0,0.05);
            }
        }

        @media(min-width: $breakpoint-lg) {
            width: 50%;
            max-width: 400px;
            border-radius: 30px 30px 30px 0px;
            padding: 30px 0px;
            position: relative;
            margin: 0;
            
            .scroll-items {
                overflow-y: scroll;
                @include scrollbar();
                max-height: calc(100vh - 210px);
                position: relative;

                .p {
                    line-height: 1.5;
                    margin-bottom: 15px;
                }
            }
            
            .list-item {
                padding: 25px 35px;
            }
        }
    }

    .detail-pop_container {
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;

        .detail-pop_overlay {
            background-color: rgba(0,0,0,0);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        @media(min-width: $breakpoint-lg) {
            width: 50%;
            max-width: 400px;
            position: absolute;
            top: 25px;
            left: 25px;
            height: auto;
            bottom: auto;
            height: 100%;
            border-radius: 30px 30px 30px 0px;
            z-index: 5;
            max-height: calc(100% - 50px);

            .detail-pop_overlay {
                display: none;
            }
        }

        @media(min-width: $breakpoint-lg) and (min-height: $breakpoint-xl) {
            max-height: calc(100% - 50px);
        }
    }

    .detail-pop {
        width: 100%;
        // max-width: 400px;
        border-radius: 30px 30px 0px 0px;
        position: absolute;
        background-color: white;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        z-index: 88;
        overflow: hidden;

        .spacer {
            width: 100%;
            height: 20px;
            display: block;

            &::before {
                content: "";
                width: 120px;
                height: 5px;
                background-color: rgba(0,0,0,0.5);
                border-radius: 2.5px;
                position: absolute;
                top: 15px;
                left: calc(50% - 60px);
                z-index: 11;
            }
        }

        .vorige {
            display: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $groen;
            color: white;
            font-size: 18px;
            line-height: 40px;
            text-align: center;
            top: 15px;
            left: 15px;
            position: absolute;
            z-index: 2;
            padding-right: 1px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: lighten($groen, 10%);
            }
        }

        figure {
            width: 100%;
            cursor: pointer;

            &::before {
                content: "";
                width: 120px;
                height: 5px;
                background-color: rgba(0,0,0,0.5);
                border-radius: 2.5px;
                position: absolute;
                top: 15px;
                left: calc(50% - 60px);
                z-index: 11;
            }
        }

        @media(min-width: $breakpoint-lg) {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: auto;
            height: 100%;
            border-radius: 30px 30px 30px 0px;
            
            .spacer,figure {
                &::before {
                    display: none;
                }
            }

            .spacer {
                height: 50px;
            }

            .vorige {
                display: block;
            }
        }

        .text {
            padding: 25px;
            cursor:pointer;

            .p {
                line-height: 1.5;
                margin-bottom: 15px;
            }
        }

        .btn {
            width: 100%;
            margin-top: 25px;
        }
    }

    @media(min-width: $breakpoint-lg) {
        // max-height: calc(100vh - 445px);
        height: calc(100vh - 100px);
        // padding-bottom: 150px;
    }

    @media(min-width: $breakpoint-lg) and (min-height: $breakpoint-xl) {
        max-height: calc(100vh - 445px);

        .list .scroll-items {
            max-height: calc(100vh - 550px);
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: transform .3s;
}

.fade-enter, .fade-leave-to {
    transform: translate(0%, 100%);
}
@media(min-width: $breakpoint-lg) {
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
}

@keyframes bounce-5 {
    0%   { background-size: auto 80px; }
    50% { background-size: auto 100px; }
    100%   { background-size: auto 80px; }
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $lichtgroen;
    background-image: url(../img/logo-groen.svg);
    background-size: auto 100px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 800;
    transition: all 0.2s ease-in-out;
    animation-name: bounce-5;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}