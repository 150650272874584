@use "../utilities/settings" as *;

section.text-image {
	
	.text-image__text {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: center;
		padding: 0 0 50px 0;
		
		@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
			padding-bottom: 100px;
		}
		
		h2, p {
			width: 100%;
		}
		
		h2 {
			margin-bottom: 30px;
		}
		
		.btn {
			margin-top: 15px;
		}
	}
	
	.text-image__image {
		border-radius: 90px 0 0 0;
		bottom: 0;
		height: 100%;
		max-height: 450px;
		aspect-ratio: 4 / 3;
		object-fit: cover;
		object-position: center center;
		right: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			max-height: 100%;
		}
		
		@media (min-width: $breakpoint-xl) {
			position: static;
		}
	}
	
	.bij {
		bottom: 250px;
		height: 115px;
		left: calc(50% - 224px);
		width: 109px;
		z-index: 2;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% - 340px);
		}
		
		@media (min-width: $breakpoint-lg) {
			bottom: -50px;
			left: calc(50% - 230px);
		}
	}

	.row {
		.planten {
			position: absolute;
			bottom:4px;
			left: 10px;
			width: 150px;
			height: 100px;

			@media(min-width: $breakpoint-md) {
				bottom: 4px;
			}
	
			.plant-animatie {
				width: 110px;
				height: 110px;
				position: absolute;
				bottom: 3px;
				left: -20px;
			}
	
			.bosje {
				position: absolute;
				left: 0px;
				bottom: 0;
				width: 100%;
				z-index: 2;
			}
	
			@media(min-width: $breakpoint-lg) {
				width: 200px;
				height: 200px;
				bottom: 5px;
				left: 50%;
	
				.plant-animatie {
					width: 160px;
					height: 160px;
					bottom: 0px;
					left: -40px;
				}
			}

			@media(min-width: $breakpoint-xl) {
				bottom: 4px;
			}
		}

		&.row-reverse {
			.text-image__image {
				border-radius: 0px 90px 0 0;
			}
		}
	}
}
