@use "../utilities/settings" as *;

.page-praktische-informatie {
	section {
		padding: 35px 0;
		position: relative;
		
		@media(min-width: $breakpoint-lg) {
			padding: 75px 0px;
		}
	}
}