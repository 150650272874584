@use "../utilities/settings" as *;

.text-video {
    
}

.video__container {
    position: relative;
    margin-top: 30px;

    @media(min-width: $breakpoint-lg) {
        margin-top: 0;
    }

    .oog {
        position: absolute;
        bottom: calc(100% - 10px);
        right: 20px;
        width: 70px;
        height: 70px;
        z-index: 1;

        @media(min-width: $breakpoint-lg) {
            right: 50px;
            width: 120px;
            height: 120px;
        }
    }
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        display: block;
    }

    .play_layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.3);
        z-index: 3;
        border-radius: 15px;
        background-image: url(../img/play_btn.svg);
        background-size: 100px auto;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        transition: all 0.2s linear;
        

        &:hover {
            background-size: 110px auto;
        }
    }
}