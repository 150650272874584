@use "../utilities/settings" as *;

.search.search-results {
	background: white;
}

#searchform-container {
	background: #eef2f8;
	display: none;
	height: 60px;
	width: 100%;
	z-index: 99;
	position: relative;
	
	@media (min-width: $breakpoint-lg) {
		height: 100px;
	}
	
	#searchform {
		height: 60px;
		
		@media (min-width: $breakpoint-lg) {
			height: 100px;
		}
		
		input {
			font-size: 16px;
			padding: 0;
			
			@media (min-width: $breakpoint-lg) {
				font-size: 2rem;
			}
		}
		
		button {
			appearance: none;
		}
	}
}

#searchform {
	align-items: center;
	background: #eef2f8;
	display: flex;
	height: 58px;
	
	input {
		appearance: none;
		background: none;
		border: 0;
		border-radius: 4px;
		display: inline-block;
		height: 58px;
		line-height: 58px;
		padding: 0 15px;
		width: calc(100% - 58px);
		
		&::placeholder {
			color: rgba($grijs, .7);
		}
		
		&:focus {
			border: 0;
			outline: 0;
		}
	}
	
	button {
		display: inline-block;
		height: 58px;
		width: 58px;
	}
}

.search-result {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 25px 15px;
	position: relative;
	transition: background .15s ease-out;
	
	&.search-result--no-result {
		text-align: center;
		
		&::before {
			display: none;
		}
	}
	
	&::before {
		content: '';
		height: 100%;
		left: 0px;
		position: absolute;
		width: 100%;
		z-index: -1;
	}
	
	&:first-of-type {
		margin-top: 35px;
	}
	
	&:nth-of-type(even) {
		&::before {
			background: rgba(#eef2f8, .66);
		}
	}
	
	&:hover {
		&::before {
			background: #eef2f8;
			transition: background .15s ease-out;
		}
	}
	
	.search-result__permalink {
		color: $grijs;
		font-size: .8em;
	}
	
}

