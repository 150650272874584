@use "../utilities/settings" as *;

.page-contact {
	background: #EEF2F8;
	
	.small-header {
		h2 {
			color: white;
			margin-bottom: 0;
		}
	}
	
	hr {
		border: 0;
		border-top: 2px solid #D7E1EF;
		display: block;
		margin: 20px 0;
		width: 100%;
	}
	
	aside {
		* {
			color: $paars;
			margin-bottom: 30px;
		}
		
		h2 {
			margin-bottom: 15px;
		}
		
		
		a {
			border-bottom: 0;
			
			&:hover {
				color: currentColor;
				opacity: .75;
			}
		}
	}
	
	.stippen {
		bottom: 0;
		opacity: .5;
		right: -90px;
		width: 220px;
		
		@media (min-width: $breakpoint-md) {
			right: -50px;
			width: 390px;
			
		}
	}
	
	.paddestoel {
		bottom: -5px;
		right: 105px;
		width: 65px;
		
		@media (min-width: $breakpoint-md) {
			right: 280px;
			width: 105px;
		}
	}
	
	.search-location.search-location--large {
		overflow: hidden;
		padding-bottom: 50px;
		
		@media (min-width: $breakpoint-lg) {
			padding-bottom: 225px;
		}
	}
}

body.hide-fish {
	.vis {
		display: none;
	}
}

.section--contactformulier {
	
	.vis {
		left: calc(50% - 690px);
		bottom: 100px;
		width: 110px;
	}
}

.contactformulier {
	background: white;
	left: 50%;
	padding: 35px 15px;
	position: relative;
	transform: translateX(-50%);
	width: 100vw;
	
	@media (min-width: $breakpoint-md) {
		border-radius: 50px;
		padding: 35px 60px;
		width: 100%;
	}
	
	hr {
		border-top: 2px solid #EEF2F8;
	}
	
	.btn {
		margin-top: 30px;
		padding: 0 70px;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			width: auto;
		}
	}
}

.telefoonnummer {
	font-size: 1.5em;
}

.section--ouderportaal {
	overflow: hidden;
	padding-bottom: 100px;
	
	@media (min-width: $breakpoint-md) {
		padding-bottom: 125px;
	}
	
	.btn {
		margin-top: 30px;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			width: auto;
		}
	}
}

.praktische-informatie {
	flex-wrap: wrap;
	left: 50%;
	position: relative;
	transform: translateX(-50%);
	width: 100vw;
	
	h2 {
		margin-bottom: 30px;
		width: 100%;
	}
	
	.praktische-informatie__blok {
		align-items: center;
		background: $lichtpaars;
		color: $paars;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		overflow: hidden;
		padding: 20px 15px;
		position: relative;
		text-align: center;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			border-radius: 50px 0 50px 50px;
			height: 160px;
			margin: 0 15px;
			max-height: 160px;
			padding: 0 15px;
			width: 160px;
		}
		
		i {
			margin-left: auto;
		}
		
		.pattern {
			//height: 100%;
			opacity: .05;
			width: 100%;
		}
		
		&:nth-of-type(odd) {
			background: $lichtbruin;
			
			&:hover {
				background: $paars;
			}
		}
		
		&:hover {
			background: $paars;
			text-decoration: none;
			
			* {
				color: white;
			}
		}
	}
}