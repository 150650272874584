@mixin scrollbar() {
    /* Turn on a 13x13 scrollbar */
  
    &::-webkit-scrollbar {
      width: 4px;
      display: none;
      position: absolute;
      background: none;
      opacity: 0;
    }
  
    &:hover::-webkit-scrollbar {
      opacity: 1;
      display: block;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }