@use "../utilities/settings" as *;

// Formulier algemeen
.formulier {
	background: white;
	color: $paars;
	font-size: 16px;
	left: 50%;
	padding: 30px 15px;
	position: relative;
	transform: translateX(-50%);
	width: 100vw;
	
	@media (min-width: $breakpoint-md) {
		border-radius: 40px 40px 0 40px;
		left: auto;
		padding: 50px;
		transform: translateX(0);
		width: auto;
	}
	
	.verberg {
		display: none;
	}
	
	&.formulier-inbakeren {
		margin-top: 30px;
		padding: 40px 30px 80px 30px;
		
		@media (min-width: $breakpoint-md) {
			border-radius: 60px 60px 0 60px;
			padding: 40px 80px 80px 80px;
			
			.verberg {
				display: block;
				visibility: hidden;
			}
		}
	}
	
	&.formulier--met-stappen {
		border-radius: 0;
		padding: 0;
		background: none;
		
		.step {
			padding: 30px 15px;
			overflow-y: hidden;
			margin-bottom: 30px;
			background: white;
			color: $paars;
			position: relative;
			
			hr {
				margin: 20px 0 0 0;
			}
			
			.btn {
				margin-top: 15px;
			}
			
			&.active {
				.step__header {
					&::after {
						display: none;
					}
					
					.step__number {
						color: white;
						background: $paars;
					}
					
					&:hover {
						.edit {
							display: none;
						}
					}
				}
				.step__form {
					display: block;
				}
			}
		}
		
		.step__number {
			font-size: 1em;
			height: 35px;
			width: 35px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 2px solid $paars;
			margin-right: 15px;
			color: inherit;
			font-weight: bold;
		}
		
		.step__title {
			color: inherit;
			font-weight: 700;
			position: relative;
			width: 100%;
		}
		
		.step__header {
			cursor: pointer;
			
			&::after {
				height: 100%;
				left: 0;
				top: 0;
				content: '';
				position: absolute;
				width: 100%;
			}
			
			&:hover {
				.step__number {
					color: white;
					background: $paars;
				}
				.edit {
					display: block;
				}
			}
			
			.edit {
				position: absolute;
				font-size: 24px;
				top: calc(50% - 12px);
				right: 30px;
				display: none;
			}
		}
		
		.step__form {
			display: none;
		}
		
		@media (min-width: $breakpoint-md) {
			border-radius: 0;
			padding: 0;
			
			.step {
				border-radius: 40px 40px 0 40px;
				padding: 15px 50px;
				
				&.active {
					padding: 50px;
				}
			}
		}
		
		.controleren__list {
			margin: 15px 0;
			
			li {
				padding: 10px 15px;
				
				&:nth-of-type(even) {
					background: #f8f9fb;
				}
			}
		}
	}
	
	small {
		color: $grijs;
	}
	
	.form-row {
		display: inline-flex;
		flex-wrap: wrap;
		margin-top: 25px;
		position: relative;
		width: 100%;
		
		&.w50 {
			width: 100%;
			
			input {
				margin: 0;
			}
			
			@media (min-width: $breakpoint-md) {
				width: calc(50% - 2.5px);
				
				input {
					margin-left: 5px;
				}
				
				&.w50--first {
					input {
						margin-left: 0;
						margin-right: 5px;
					}
					
					label.error {
						padding-left: 0;
					}
				}
				
				&.w50--last {
					label:not(.error) {
						padding-left: 5px;
					}
				}
				
				label.error {
					padding-left: 5px;
				}
			}
		}
		
		label:not(.error) {
			color: inherit;
			font-weight: 700;
			line-height: 1.3;
			margin-bottom: 5px;
			width: 100%;
		}
		
		input, textarea, select {
			appearance: none;
			border: 2px solid #DFE6F0;
			border-radius: 4px;
			font-size: 16px;
			margin: 0;
			padding: 0 15px;
			position: static;
			width: 100%;
			
			&.error {
				border-color: #dc3232;
				margin-bottom: 10px;
			}
			
			&:focus {
				border: 2px solid $paars;
				outline: $paars;
			}
			
			&::placeholder {
				color: $grijs;
			}
		}
		
		input, select {
			height: 58px;
			line-height: 58px;
		}
		
		textarea {
			line-height: 1.3;
			padding: 15px;
			resize: none;
		}
		
		select {
			appearance: auto;
		}
		
		label.error {
			bottom: -8px;
			color: #dc3232;
			font-size: 14px;
			font-weight: 400;
			position: absolute;
		}
	}
	
	hr {
		border: 0;
		border-top: 2px solid #EEF2F8;
		height: 0;
		margin: 40px 0 10px;
	}
	
	.btn {
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			padding: 0 70px;
			width: auto;
		}
	}
}

// Contact Form 7 formulier
.wpcf7-form {
	
	.wpcf7-form-control:active, .wpcf7-form-control:focus  {
		border-color: $paars !important;
	}
	br {
		display: none;
	}
	> p {
		//position: relative;
		margin: 10px 0 0;
		
		.label {
			background: #fff;
			color: $paars;
			//position: absolute;
			//top: 15px;
			font-size: 14px;
			font-weight: 500;
			left: 25px;
			padding: 2.5px 5px;
			z-index: 2;
		}
	}
	
	small {
		color: $grijs;
		display: block;
		margin-bottom: 20px;
	}
	
	.wpcf7-form-control-wrap {
		width: 100%;
		
		&.file, &.file-1, &.file-2, &.file-3, &.file-4 {
			display: block;
			margin: 5px 0 30px;
			width: 100%;
			
			> input {
				width: 100%;
			}
		}
		
		input:not([type=checkbox]):not([type=file]):not([type=radio]), input:not([type=checkbox]):not([type=file]):not([type=radio]):focus {
			appearance: none;
			//border-radius: 32px 0 32px 32px;
			border: 2px solid #DFE6F0;
			border-radius: 4px;
			font-size: 1.1rem;
			height: 58px;
			line-height: 58px;
			overflow: hidden;
			//padding: 0 15px 0 30px;
			padding: 0 15px;
			width: 100%;
		}

		select {
			appearance: none;
			//border-radius: 32px 0 32px 32px;
			border: 2px solid #DFE6F0;
			border-radius: 4px;
			font-size: 1.1rem;
			height: 58px;
			line-height: 58px;
			overflow: hidden;
			//padding: 0 15px 0 30px;
			padding: 0 15px;
			width: 100%;

			&::after {
				content: "hello";
			}
		}
		
		.wpcf7-checkbox .wpcf7-list-item,
		.wpcf7-radio .wpcf7-list-item {
			display: block;
			margin: 0 0 0 2em;
			
			input {
				margin-left: -1em;
			}
		}
		
		input:focus, textarea:focus {
			border: 2px solid $paars;
			outline: 0;
		}
		
		textarea {
			border: 2px solid #DFE6F0;
			//border-radius: 32px 0 32px 32px;
			border-radius: 4px;
			height: 235px;
			max-height: 235px;
			max-width: 100%;
			min-height: 235px;
			min-width: 100%;
			padding: 15px;
			//padding: 15px 15px 15px 30px;
			resize: none;
			width: 100%;
		}
		
		.wpcf7-not-valid {
			border-color: #dc3232;
		}
	}
	
	.wpcf7-not-valid-tip {
		color: #dc3232;
		font-size: 14px;
	}
	
	.wpcf7-response-output {
		font-size: 1.5em;
		margin: 0;
		text-align: center;
		font-family: $heading-font;
		color: $groen;
		background: none;
		border: 0;
		height: calc(100% + 30px);
		width: 100%;
	}
	
	&.sent {
		* {
			display: none;
		}
		
		.wpcf7-response-output {
			display: block;
			margin: 0;
			border: 0;
		}
	}
	
	.btn {
		appearance: none;
		border: 0;
	}
}

label.error {
	color: #dc3232;
}

form[data-status=invalid] .wpcf7-response-output {
	font-size: 1em;
	max-width: 500px;
	margin: 15px auto 0 auto !important;
	padding: 15px 30px !important;
	//display: none;
}