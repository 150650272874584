@use "../utilities/settings" as *;

.locatie-slider {
    &__container {
        width: calc(100% + 50px);
        margin: 0px -25px;
    }

    &__item {
        padding: 25px;
        outline: none;
        position: relative;
        cursor: pointer;
        height: 100%;
        display: flex;
        padding-bottom: 95px;

        .text {
            position: relative;
        }

        figure {
            margin-bottom: 25px;
            border-radius: 15px 15px 15px 0px;
            overflow: hidden;
        }

        .cats {
            position: absolute;
            top: 0;
            right: 0;

            .cat {
                padding: 7px 8px;
                background-color: $groen;
                border-radius: 0px 10px 10px 10px;
                font-size: 0.8em;
                color: white;
                font-weight: 600;
            }
        }

        .btn {
            position: absolute;
            bottom: 25px;
        }

        // @media(min-width: $breakpoint-xl) {
        //     .btn {
        //         position: absolute;
        //         right: 0;
        //         bottom: 0;
        //     }
        // }

        &:hover {
            background-color: rgba(0,0,0, 0.02);
            border-radius: 25px;
        }
    }
}