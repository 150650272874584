@use "../utilities/settings" as *;

section.text-image-golf {
	background: $lichtgroen;
	min-height: 500px;
	overflow-x: hidden;
	overflow-y: hidden;
	padding-bottom: 160px;

	&.height {
		min-height: 700px;
	}

	h2 {
		color: $groen;
	}
	
	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
	}
	
		
	.text-image__text {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: center;
		padding: 0 0 50px 0;
		
		@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
			padding-bottom: 100px;
		}
		
		h2, p {
			width: 100%;
		}
		
		h2 {
			margin-bottom: 30px;
		}
		
		.btn {
			margin-top: 15px;
		}
	}
	
	.text-image__image_frontpage {
		position: absolute;
		bottom: 0;
		left: auto;
		right: 35px;
		width: 280px;
		height: auto;
		max-height: 95%;
		object-fit: contain;
		
		@media (min-width: $breakpoint-md) {
			right: calc(50% - 595px);
			width: 550px;
		}
	}
	
	.text-image__image {
		border-radius: 90px 0 0 0;
		bottom: 0;
		height: 100%;
		max-height: 450px;
		aspect-ratio: 4 / 3;
		object-fit: cover;
		object-position: center center;
		right: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			max-height: 100%;
			position: static;
		}
		
		@media (min-width: $breakpoint-xl) {
			position: static;
		}
	}
	
	.bij {
		bottom: 250px;
		height: 115px;
		left: calc(50% - 224px);
		width: 109px;
		z-index: 2;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% - 340px);
		}
		
		@media (min-width: $breakpoint-lg) {
			bottom: -50px;
			left: calc(50% - 230px);
		}
	}

	.row {
		.planten {
			position: absolute;
			bottom:3px;
			left: 10px;
			width: 150px;
			height: 100px;

			@media(min-width: $breakpoint-md) {
				bottom:4px;
			}
	
			.plant-animatie {
				width: 150px;
				height: 150px;
				position: absolute;
				bottom: 3px;
				left: -40px;
			}
	
			.bosje {
				position: absolute;
				left: 0px;
				bottom: 0;
				width: 100%;
				z-index: 2;
			}
	
			@media(min-width: $breakpoint-lg) {
				width: 200px;
				height: 200px;
				bottom: 4px;
				left: 50%;
	
				.plant-animatie {
					width: 200px;
					height: 200px;
				}
			}
		}

		&.row-reverse {
			.text-image__image {
				border-radius: 0px 90px 0 0;
			}
		}
	}

	.stippen {
		bottom: -100px;
		right: 85px;
		width: 250px;

		@media (min-width: $breakpoint-md) {
			left: 0px;
			right: auto;
			width: 390px;
		}
	}

	.ruimtevaarder {
		bottom: 0;
		left: auto;
		right: 35px;
		width: 280px;
		height: auto;
		max-height: 95%;
		object-fit: contain;
		
		@media (min-width: $breakpoint-lg) {
			right: calc(0px);
			width: 550px;
			bottom: -100px;
			max-height: 660px;
		}
	}
	
	.golf {
		background-image: url(../img/golf-shape.svg);
		background-repeat: repeat-x;
		background-size: auto 100%;
		bottom: -1px;
		height: 100px;
		width: 100%;
	}

	.alien {
		display: none;
		bottom: 200px;
		right: -30px;
		width: 100px;
		height: 100px;
		z-index: 1;
		transform: rotate(-40deg);
		
		@media (min-width: $breakpoint-md) {
			display: block;
			bottom: 50%;
			width: 150px;
			height: 150px;
			right: -60px;
		}

        @media (min-width: $breakpoint-lg) {
			width: 200px;
			height: 200px;
		}

        @media (min-width: $breakpoint-xl) {
			width: 225px;
			height: 225px;
		}
	}

	&.frontpage {
		background: $lichtgroen;
		min-height: 550px;
		overflow-x: hidden;
		overflow-y: hidden;
	
		h2 {
			color: $groen;
		}
		
		@media (min-width: $breakpoint-md) {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			min-height: 700px;
		}
		
		.stippen {
			bottom: 0;
			right: 85px;
			width: 250px;
			
			@media (min-width: $breakpoint-md) {
				left: calc(50% - 30px);
				right: auto;
				width: 390px;
			}
		}
		
		.ruimtevaarder {
			bottom: 0;
			left: auto;
			right: 35px;
			width: 280px;
			height: auto;
			max-height: 95%;
			object-fit: contain;

			@media (max-width: 650px) {
				max-height: 45%;
			}
			
			@media (min-width: $breakpoint-md) {
				right: calc(50% - 595px);
				width: 550px;
			}
		}
		
		.golf {
			background-image: url(../img/golf-shape.svg);
			background-repeat: repeat-x;
			background-size: auto 100%;
			bottom: -1px;
			height: 100px;
			width: 100%;
		}
	}
}