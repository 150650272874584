@use "../utilities/settings" as *;

.search-location {
    background-color: $lichtbruin;
    position: relative;
    z-index: 3;
	
	&.search-location--large {
		padding: 50px 0 150px;
		
		@media (min-width: $breakpoint-lg) {
			padding: 125px 0 300px;
		}
		
		h2 {
			@media (min-width: $breakpoint-md) {
				margin-bottom: 50px;
			}
		}
		
		.eend {
			display: none;
			
			@media (min-width: $breakpoint-lg) {
				left: -20px;
				bottom: -20px;
				height: 310px;
				width: auto;
				display: block;
			}
		}
	}

    .animatie-wrapper .boom {
        position: absolute;
        bottom: 0px;
        left: -40px;
        width: 270px;
        height: 270px;
        display: none;

        @media(min-width: $breakpoint-xl) {
            display: block;
        }
    }

    .locatie-eend {
        position: absolute;
        width: 130px;
        height: 130px;
        bottom: 0px;
        left: 100px;
        display: none;

        @media(min-width: $breakpoint-xl) {
            display: block;
        }
    }

    &.large-padding {
        .animatie-wrapper .boom {
            bottom: 0px;
            width: 350px;
            height: 350px;
            left: 0;
        }
    }

    .form-box {
        max-width: 1000px;
        margin: 0px auto;
        position: relative;
       
        input {
            z-index: 3;
        }
        .suggestions {
            position: absolute;
            top: calc(100% + 5px);
            left: 0px;
            width: 100%;
            background-color: white;
            z-index: 2;
            border-radius: 15px;
            -webkit-box-shadow: 0 2px 30px 0 rgba(0,0,0,0.05);
            box-shadow: 0 2px 30px 0 rgba(0,0,0,0.05);
            overflow: hidden;

            @media(min-width: $breakpoint-md) {
                width: calc(100% - 230px);
            }

            li {
                padding: 0px 25px;
                color: #666;
                cursor: pointer;
                border-bottom: 1px solid rgba(0,0,0,0.05);
                font-size: 0.9em;
                position: relative;
                line-height: 55px;
                height: 54px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 50px;
                white-space: nowrap;
                list-style: none;
                

                .icon {
                    font-size: 110%;
                    color: #ccc;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }

                &:last-child {
                    border-bottom: none;
                }

                &::after {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f061";
                    font-size: 80%;
                    color: #ccc;
                    position: absolute;
                    right: 0px;
                    top: 0;
                    line-height: 54px;
                    width: 50px;
                    height: 100%;
                    text-align: center;
                    background-color: white;
                }

                &:hover {
                    color: $groen;

                    &::after {
                        color: inherit;
                    }

                    .icon {
                        color: inherit;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    form {

        input {
            border-radius: 30px 0px 0px 30px;
            border: none;
            padding: 0px 25px;
            width: 100%;
            outline: none;
            font-size: 16px;
        }

        select {
            margin-top: 25px;
            position: relative;
            display: inline-block;
            width: 100%;
            text-align: left;
            border-radius: 25px;
            font-family: $button-font;
            font-size: 14px;
            color: $groen;
            padding: 15px 20px 15px 20px;
            outline: none;
            border: none;
            appearance: none;
            background: #fff url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%234e643c" d="M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0L224 284.5l154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.4-24.6 9.4-33.9 0z"/></svg>') no-repeat right 19px center;
            background-size: 9px;
            cursor: pointer;

            

            @media(min-width: $breakpoint-lg) {
                font-size: 14px;
                border-radius: 0px;
                background-color: $paars;
                margin: 0;
                padding: 0px 0px 0px 20px;
                appearance: none;
                background: #FFF;
                position: static;
                width: 27%;
                color: $groen;
                background: #fff url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%234e643c" d="M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0L224 284.5l154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.4-24.6 9.4-33.9 0z"/></svg>') no-repeat right 15px center;
                background-size: 9px;
                cursor: pointer;
                border-left: 1px solid #ccc;
                font-family: $button-font;
                transition: all .2s linear;
                font-size: 1em;

                &.error {
                    border-color: #dc3232;
                    margin-bottom: 10px;
                }
            
                &:focus {
                    border-left: 2px solid #ccc;
                }
                
                &::placeholder {
                    color: $grijs;
                }
            }	
		}
		
		input, select {
            @media(min-width: $breakpoint-lg) {
                height: 58px;
                line-height: 58px;
                font-size: 14px;
            }
        

            @media(min-width: $breakpoint-xl) {
                font-size: 16px; 
            }
			
		}

        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            text-align: center;
            font-size: 0;
            height: 58px;
            line-height: 58px;

            .fas {
                font-size: 18px;
            }

            @media(min-width: $breakpoint-md) {
                width: auto;
                // flex:  0 0 230px;
                font-size: 1em;
            }
        }

        .checkboxes {
            margin-top: 25px;
            position: relative;
            display: table;
            width: 100%;
            text-align: center;

            .checkboxes_label {
                padding: 15px 20px; 
                background-color: white;
                width: 100%;
                font-size: 14px;
                font-family: $button-font;
                color: $groen;
                position: relative;
                display: flex;
                cursor: pointer;
                border-radius: 25px;

                &::after {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f107";
                    font-size: 95%;
                    color: inherit;
                    text-align: right;
                    margin-left: auto;
                    position: relative;
                    top: 3px;
                }

                @media(min-width: $breakpoint-lg) {
                    display: none;
                }
            }

            &.active .checkboxes_label {
                border-radius: 25px 25px 0px 0px;

                &::after {
                    content: "\f106";
                }
            }

            .checkboxes__inner {
                display: none;
                width: 100%;
                position: absolute;
                top: 100%;
                border-radius: 0px 0px 25px 25px;
                overflow: hidden;

                @media(min-width: $breakpoint-lg) {
                    display: block;
                    width: auto;
                    margin: 0px auto;
                    position: relative;
                    top: 0;
                }

                &.active {
                    display: block;
                }
            }

            .checkbox {
                display: block;
                cursor: pointer;
                width: 100%;

                label {
                    font-family: $button-font;
                    letter-spacing: 0.03em;
                    padding: 15px 20px;
                    margin: 0;
                    // display: inline-block;
                    display: block;
                    text-align: left;
                    background-color: white;
                    color: $groen;
                    font-size: 14px;

                    &::before {
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f111";
                        font-size: 95%;
                        text-align: right;
                        position: relative;
                        margin-right: 5px;
                        color: #ccc;
                    }

                    &:hover {
                        background-color: lighten($groen, 60%);

                        &::before {
                            content: "\f058";
                            color: lighten($groen, 30%);
                        }
                    }
                }

                @media(min-width: $breakpoint-lg) {
                    display: inline-block;
                    margin: 0px 10px;
                    width: auto;

                    label {
                        border-radius: 25px;
                    }
                }
            }

            .checkbox input {
                display: none;
            }

            .checkbox input:checked ~ label {
                background-color: $groen;
                color: white;

                &::before {
                    content: "\f058";
                    color: inherit;
                }
            }
        }
    }
}