@use "../utilities/settings" as *;

section.categorie {
	padding-bottom: 150px;
	overflow-x: hidden;
	overflow-y: hidden;
	
	.ij {
		height: 576px;
		right: -80px;
		top: 58px;
		width: 384px;
	}
	
	.col__categorie {
		margin-top: 30px;
		
		&:nth-child(1) {
			.card-category__icon {
				height: 120px;
				top: 0;
			}
			
			.card-category__content {
				background-color: $lichtgrijs;
			}
		}
		
		&:nth-child(2) {
			.card-category__icon {
				height: 125px;
				top: 0px;
			}
			
			.card-category__content {
				background-color: $lichtpaars;
			}
		}
		
		&:nth-child(3) {
			.card-category__icon {
				top: -10px;
				height: 140px;
			}
			
			.card-category__content {
				background-color: $lichtgrijs;
			}
		}
		
		&:nth-child(4) {
			.card-category__icon {
				top: 0px;
				height: 125px;
			}
			
			.card-category__content {
				background-color: $lichtpaars;
			}
		}
	}
	
	.card-category {
		overflow: hidden;
		position: relative;
		height: 100%;
		flex: 1;
		transition: all 0.2s ease-in-out;

		&:hover {
			.card-category__icon {
				transform: translateX(-50%) scale(1.1);
			}
		}
	}
	
	.card-category__icon {
		height: 100px;
		left: 50%;
		position: absolute;
		z-index: 1;
		transform: translateX(-50%);
		transition: all 0.2s ease-in-out;
	}
	
	.card-category__title {
		margin-bottom: 20px;
		min-height: 50px;
	}
	
	.card-category__content {
		border-radius: 0 0 30px 0;
		margin-top: 58px;
		padding: 90px 10px 30px 10px;
		width: 100%;
		background-size: cover;
		position: relative;
		display: flex;
		flex-flow: column wrap;
		
		@media (min-width: $breakpoint-md) {
			padding: 90px 30px 30px 30px;
		}
		
		ul {
			margin-bottom: 40px;
			list-style: none;
			
			li {
				padding: 5px 0;
			}
		}
		
		.btn {
			margin: auto auto 0 auto;
			position: relative;
			z-index: 2;
		}
	}
}