// kleuren
// primaire kleuren
$paars: #42325E;
$groen: #4E643C;
$zwart: #232323;
$grijs: #969696;
$rood: #9E2732;
$oranje: #CD5C2F;
$roze: #CCAAB2;
$blauw: #5187B9;
$geel: #EDCD44;

// lichte kleuren
$lichtgroen: #C8DCD1;
$lichtpaars: #CCAAB2;
$lichtbruin: #E9E1D6;
$lichtgrijs: #F4F4F4;
$lichtgeel: #F9EEBE;

// fonts
@font-face {
    font-family: 'vag_rundschrift_dregular';
    src:url('../fonts/vagrundschriftd-regular-webfont.woff2') format('woff2'),
        url('../fonts/vagrundschriftd-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'koara_bold';
    src: url('../fonts/koara_bold.woff2') format('woff2'),
         url('../fonts/koara_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$button-font: "vag_rundschrift_dregular", "Roboto", sans-serif;
$heading-font: "koara_bold", "Roboto", sans-serif;

// breakpoints
$breakpoint-xs: 452px;
$breakpoint-sm: 540px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;

