@use "../utilities/settings" as *;

.section.pagination {
	padding-top: 0;
}

.posts-pagination {
	a, p, span {
		line-height: 35px;
	}
	
	> * {
		padding: 0 5px;
	}
}

.page-numbers {
	&:hover {
		color: $lichtpaars;
		text-decoration: none;
	}
	
	&.next, &.prev {
		&:hover {
			.show-md {
				color: $lichtpaars;
			}
			
			.pagination__button {
				background: darken($lichtpaars, 10%);
				transition: .2s ease-out;
			}
		}
	}
	
	&.current {
		color: $lichtpaars;
		font-weight: 700;
	}
}

.pagination__button {
	align-items: center;
	background: $lichtpaars;
	border-radius: 50%;
	display: inline-flex;
	height: 34px;
	justify-content: center;
	transition: .2s ease-out;
	width: 34px;
	
	i {
		color: white;
	}
	
	&.pagination__button--prev {
		margin-right: 7.5px;
	}
	
	&.pagination__button--next {
		margin-left: 7.5px;
	}
}