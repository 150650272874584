@use "../utilities/settings" as *;

.text-button {
    background-color: $lichtbruin;
    padding: 50px 0px;
	
	&__white {
		background: transparent;
	}
	
    &__roze {
        background-color: $roze;
    }

    &__bruin {
        h2 {
            color: $groen;
        }
    
        .btn {
            background-color: $groen;
    
            &:hover {
                background-color: lighten($groen, 10%);
            }
        }
    }

    @media(min-width: $breakpoint-lg) {
        padding: 100px 0px;
    }

    h2,p {
        text-align: center;
    }
	
	&__text-left {
		h2, p {
			text-align: left;
		}
	}

    .btn {
        margin: 0px auto;
        display: table;
        margin-top: 20px;
    }

    .bomen {
        position: absolute;
        bottom: 0;
        right: -10%;
        max-width: 25%;
        max-height: 90%;
        object-fit: contain;

        @media(min-width: $breakpoint-xxl) {
            right: 3%;
        }

        @media(min-width: 1920px) {
            right: 10%;
        }
    }
}