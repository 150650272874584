@use "../utilities/settings" as *;

.text-only {
    background-repeat: no-repeat;
	background-position: right -40px bottom;
	background-size: 310px;

	p {
		z-index: 6;
		position: relative;
	}

    .alien {
		display: none;
		bottom: 200px;
		right: -30px;
		width: 100px;
		height: 100px;
		z-index: 1;
		transform: rotate(-40deg);
		
		@media (min-width: $breakpoint-md) {
			display: block;
			bottom: 50%;
			width: 150px;
			height: 150px;
			right: -60px;
		}

        @media (min-width: $breakpoint-lg) {
			width: 200px;
			height: 200px;
		}

        @media (min-width: $breakpoint-xl) {
			width: 225px;
			height: 225px;
		}
	}

    .alignment_center {
        justify-content: center;
    }

    .alignment_left {
        justify-content: flex-start;
    }

    .alignment_right {
        justify-content: flex-end;
    }

	.watermerk {
		z-index: 10;
		position: relative;
	}
}