@use "../utilities/settings" as *;

.faq {
	background: #EEF2F8;
	
	.faq__vraag {
		cursor: pointer;
		font-size: 1em;
		line-height: 30px;
		margin-bottom: 15px;
		padding-left: 50px;
		position: relative;
		
		&:hover {
			color: lighten($paars, 15%);
			
			.circle {
				background: lighten($paars, 15%);
			}
		}
	}
	
	.faq__antwoord {
		padding-left: 50px;
	}
	
	.circle {
		background-color: $paars;
		border-radius: 50%;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
		height: 30px;
		left: 0;
		line-height: 30px;
		margin-right: 25px;
		position: absolute;
		text-align: center;
		width: 30px;
		
		&::after {
			font-size: 0.7em;
			font-family: "Font Awesome 5 Free";
			font-variant: normal;
			display: inline-block;
			font-weight: 900;
			content: "\f067";
			line-height: 1.05;
		}
	}
	
	ul {
		margin-bottom: 15px;
		
		li a {
			font-weight: 500;
		}
	}
	
	.faq__link {
		cursor: pointer;
	}
	
	.text {
		display: none;
	}
	
	&.hover {
		h2 {
			margin-bottom: 0;
		}
		
		.circle {
			&::after {
				content: "\f068";
			}
		}
		
		.text {
			display: block;
			margin-bottom: 25px;
		}
	}
	
	.formbox {
		max-width: 1000px;
		margin: 0px auto;
		position: relative;
		
		.suggestions {
			position: absolute;
			top: calc(100% + 5px);
			left: 0px;
			width: 100%;
			background-color: white;
			z-index: 2;
			border-radius: 15px;
			-webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,0.25);
			box-shadow: 0 15px 30px 0 rgba(0,0,0,0.25);
			overflow: hidden;
			
			li {
				padding: 0px 25px;
				color: #666;
				cursor: pointer;
				border-bottom: 1px solid rgba(0,0,0,0.05);
				font-size: 0.9em;
				position: relative;
				line-height: 55px;
				height: 54px;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 50px;
				white-space: nowrap;
				
				
				.icon {
					font-size: 110%;
					color: #ccc;
					margin-right: 5px;
					position: relative;
					top: 1px;
				}
				
				&:last-child {
					border-bottom: none;
				}
				
				&::after {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					content: "\f061";
					font-size: 80%;
					color: #ccc;
					position: absolute;
					right: 0px;
					top: 0;
					line-height: 54px;
					width: 50px;
					height: 100%;
					text-align: center;
					background-color: white;
				}
				
				&:hover {
					color: $groen;
					
					&::after {
						color: inherit;
					}
					
					.icon {
						color: inherit;
						opacity: 0.8;
					}
				}
				
				a::after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					top: 0;
					right: 0;
				}
			}
		}
	}
}


.sidebar-menu {
	background: white;
	border-radius: 40px 0 40px 0;
	margin-bottom: 30px;
	overflow: hidden;
	
	&.sticky {
		@media (min-width: $breakpoint-md) {
			position: sticky;
			top: 107px;
		}
	}
	
	nav {
		ul {
			list-style: none;
			
			li {
				position: relative;
				
				&.active {
					background: #CDD9EB;
					
					&:hover {
						background: #CDD9EB;
					}
				}
				
				&:hover {
					background:rgba(#CDD9EB, 0.65);
				}
				
				a {
					color: $paars;
					display: block;
					font-weight: bold;
					height: 100%;
					padding: 25px;
					text-decoration: none;
					width: 100%;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

.content[data-category] {
	display: none;
}

.faq__search {
	display: flex;
	height: 50px;
	width: 100%;
	margin-bottom: 35px;
	
	input[type=search] {
		line-height: 50px;
		padding: 0 25px;
		width: calc(100% - 60px);
		border-radius: 25px 0 0 25px;
		border: 0;
		
		&::placeholder {
			color: #868686;
		}
		
		&:focus {
			outline: 0;
		}
	}
	
	button[type=submit] {
		background: $paars;
		border-radius: 0 25px 25px 0;
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s linear;
		
		i {
			color: white;
		}
		
		&:hover {
			background: rgba($paars, .85);
			transition: all .2s linear;
		}
	}
}