@use "../utilities/settings" as *;

.full-width-slider {
    
    h2 {
        color: #4e643c;
    }
  
    .slide {
        width: 100%;
        display: table;
        padding-top: 0;
        height: auto;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-radius: 0px 0px 50px 0px;
            overflow: hidden;

            @media(min-width: $breakpoint-lg) {
                height: 500px;
            }
        }
    }

    .slick-button {
        margin-top: -36px;
    }
}

.slick-button {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -28px;
    display: block;
    background-color: #CCAAB2;
    border-radius: 50%;
    z-index: 2;

    .fa {
        font-size: 18px;
        color: white;
        line-height: 40px;
    }

    &.slick-prev {
        left: 3%;

        .fa {
            margin-left: -3px;
        }
    }

    &.slick-next {
        right: 3%;

        .fa {
            margin-right: -3px;
        }
    }

    @media(min-width: $breakpoint-xl) {
        width: 56px;
        height: 56px;
        border-radius: 50%;

        .fa {
            font-size: 25px;
            line-height: 56px;
            margin: 0;
        }

        &.slick-prev {
            right: calc(100% + 10px);
            left: auto;
            border-radius: 50%;
        }
    
        &.slick-next {
            left: calc(100% + 10px);
            right: auto;
            border-radius: 50%;
        }
    }
}