@use "../../utilities/settings" as *;

.single-post {
	.video-blok {
		overflow: hidden;
		
		p {
			text-align: center;
		}
		
		.video-container-wrapper {
			position: relative;
			margin-top: 30px;
			
			> img {
				width: 100vw;
				position: relative;
				left: 50%;
				transform: translate(-50%, 0);
				
				@media (min-width: $breakpoint-md) {
					width: 100%;
					position: static;
					left: auto;
					transform: translate(0, 0);
				}
			}
			
			.video-container {
				position: absolute;
				height: 0;
				padding-top: 56.25%;
				margin-top: 50px;
				display: block;
				width: 100vw;
				left: 50%;
				transform: translate(-50%, 0);
				top: 8px;
				
				>.overlay{
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($zwart, .3);
					z-index:1;
					cursor: pointer;
				}
				
				@media (min-width: $breakpoint-sm) {
					top: 35px;
					//left: 30px;
				}
				
				@media (min-width: $breakpoint-md) {
					transform: translate(0, 0);
					//width: calc(100% - 60px);
					padding-top: 50.25%;
					left: 60px;
					width: calc(100% - 120px);
					top: 42px;
				}
				
				@media (min-width: $breakpoint-lg) {
					top: 74px;
				}
				
				@media (min-width: $breakpoint-xl) {
					top: 100px;
				}
			}
			
			.video-container video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
			
			.video-container .playbutton {
				position: absolute;
				z-index: 2;
				width: 90px;
				height: 90px;
				left: calc(50% - 45px);
				top: calc(50% - 45px);
				cursor: pointer;
			}
			
			.video-container img:not(.videoposter):not(.playbutton) {
				//display: none;
			}
			
			.video-container .videoposter {
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				cursor: pointer;
				object-fit: cover;
				
			}
		}
		
		.boompje {
			bottom: auto;
			
			@media (min-width: $breakpoint-md) {
				left: 15px;
				top: -66px;
			}
			@media (min-width: $breakpoint-lg) {
				top: -89px;
			}
			@media (min-width: $breakpoint-xl) {
				top: -128px;
			}
		}
	}
}