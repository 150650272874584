figure {
	background-color: #f1f1f1;
	background-image: url(../img/logo-blauw-transparant.svg);
	background-size: 50% auto;
	background-repeat: no-repeat;
	background-position: center center;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
	
	img {
		height: 100%;
		left: 50%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		background-color: #f1f1f1;
	}
}