@use "../utilities/settings" as *;

section.elke-dag-iets-leuks {
	overflow-x: hidden;
	
	p:last-child {
		margin-bottom: 30px;
	}
	
	.row-slider {
		width: 100vw;
		
		@media (min-width: $breakpoint-lg) {
			width: auto;
		}
		
		.slick-list {
			overflow-x: hidden;
			padding: 0 20% 0 0;
			
			@media (min-width: $breakpoint-xs) {
				padding: 0 30% 0 0;
			}
			
			@media (min-width: $breakpoint-lg) {
				margin: 0;
				padding: 0;
			}
		}
	}
	
	.locatienaam {
		margin-top: auto;
		font-size: 12px;
	}
	
	.btn {
		margin-top: 50px;
	}
	
	.slick-next, .slick-prev {
		background: #CCAAB2;
		position: absolute;
		//right: 10px;
		right: auto;
		left: calc(100vw - 70px);
		top: calc(50% - 2px);
		transform: translateY(-50%);
		border-radius: 50%;
		height: 47px;
		width: 47px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 12px 0 rgba($zwart, .35);
		//left: auto;
		
		@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
			left: calc(100vw - 175px);
		}
		
		i {
			font-size: 1.6rem;
			color: white;
		}
	}
	
	.slick-prev {
		top: calc(50% + 58px);
	}
	
	.slick-disabled {
		background: #ccc;
		opacity: .4;
	}
	
	.card {
		height: 100%;
		
		@media(min-width: $breakpoint-md) {
			height: calc(100% - 30px);
			margin-bottom: 30px;
		}
		
		h3 {
			margin-bottom: 15px;
		}
		
		.card__content {
			display: flex;
			flex-direction: column;
		}
		
		p:not(.card__date) {
			margin-bottom: 0;
		}
		
		p.card__description {
			margin-bottom: 15px;
		}
	}
}