@use "../utilities/settings" as *;

.text-2col {
    overflow: hidden;

    .column-count {
	    //padding: 0 15px;
	    
        @media(min-width: $breakpoint-lg) {
            column-count: 2;
            column-gap: 50px;
        }
    }
}