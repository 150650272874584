@use "../utilities/settings" as *;

.hero {
	align-items: flex-start;
	background-color: $roze;
	background-image: url("../img/bg_hero--mobile.jpg");
	background-position: 80% 40%;
	background-repeat: no-repeat;
	background-size: auto 100%;
	height: 60vh;
	max-height: 320px;
	position: relative;
	width: 100%;
	
	@media (min-width: $breakpoint-md) {
		background-image: url("../img/bg_hero.jpg");
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-height: 660px;
	}
	
	@media (min-width: $breakpoint-xl) {
		background-position: 50% 50%;
	}
	
	video {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 0;
		left: 0;
	}
	
	h1 {
		text-align: center;
		
		@media (min-width: $breakpoint-md) {
			text-align: left;
		}
	}
	
	.buttons {
		.btn:first-of-type {
			margin-right: 10px;
		}
	}
}

.hero__content {
	padding: 25px 0;
	width: 100%;
}