@use "../utilities/settings" as *;

.werken-bij__header {
	background-color: $lichtpaars;
	margin-bottom: 25px;
	min-height: 30vh;
	position: relative;
	
	h1 {
		font-size: 3.333em;
	}
	
	p {
		margin: 25px 0;
	}
	
	&--image {
		background-color: black;
		border-radius: 125px 0 0 125px;
		height: 130px;
		padding-top: 0;
		position: absolute;
		right: 0;
		top: -70px;
		width: 50vw;
		
		@media (min-width: $breakpoint-md) {
			border-radius: 250px 0 0 250px;
			height: 500px;
			top: 100px;
		}
		
		img {
			height: 100%;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			transform: translate(0%, 0%);
			width: 100%;
		}
	}
	
	.bij {
		width: 110px;
		bottom: -40px;
		left: calc(50% - 150px);
		transform: scaleX(-1);
	}
	
	.werken-bij-boom {
		width: 205px;
		left: -31px;
		bottom: 0;
	}
	
	.btn {
		margin-bottom: .5em;
	}
}

.werken-bij__intro {
	h2 {
		padding-top: 50px;
		
		@media (min-width: $breakpoint-md) {
			padding-top: 0;
		}
	}
	
	.werken-bij__intro-tekst {
		margin: 25px 0 35px;
	}
	
	.ij {
		height: 576px;
		right: -80px;
		top: 265px;
		width: 384px;
	}
}

section.kernwaarden {
	
	.col__card {
		
		&:nth-child(2) {
			.card__title {
				color: $groen;
			}
		}
	}
	
	.card__content {
		text-align: center;
	}
	
	.card__title {
		color: $paars;
		margin-bottom: 15px;
	}
	
	.card {
		position: relative;
		
		&.card--lichtpaars {
			background: rgba($lichtpaars, 0.5);
		}
		
		&.card--bruin {
			background: $lichtbruin;
		}
		
		figure {
			clip-path: circle(84% at 50% 0%);
		}
	}
}

.stage {
	background-color: $lichtgroen;
	
	.stage__image {
		border-radius: 100px 0 0 0;
		bottom: -25px;
		height: 235px;
		object-fit: cover;
		position: absolute;
		right: 0;
		width: 90%;
		
		@media (min-width: $breakpoint-md) {
			height: calc(100% + 25px);
			width: 50%;
			bottom: 0;
		}
	}
	
	.animatie-wrapper .boom {
		bottom: -50px;
		left: 0;
		width: 120px;
		height: 120px;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% - 85px);
			right: auto;
			bottom: -50px;
			width: 240px;
			height: 240px;
		}
	}
	
	p {
		margin: 25px 0 30px;
	}
	
	.stage__content {
		padding-bottom: 215px;
		
		@media (min-width: $breakpoint-md) {
			padding-bottom: 0;
		}
	}
}