@use "../../utilities/settings" as *;

.single-post {
	.quote-groen {
		blockquote {
			padding-top: 115px;
			color: $groen;
			font-size: 35px;
			line-height: 43px;
			padding-left: 0;
			margin: 0;
			
			p {
				color: $groen;
			}
			
			&::before {
				width: 110px;
				height: 90px;
				background-image: url(../img/quote-groen.svg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}
		
		h2 {
			color: $groen;
		}
		
		.slang {
			top: auto;
			bottom: 15px;
			
			@media (min-width: $breakpoint-xl) {
				width: 150px;
				height: 150px;
			}
			
			@media (min-width: $breakpoint-xxl) {
				width: 180px;
				height: 180px;
				left: 0;
			}
		}
	}
	
}