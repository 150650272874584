@use "../utilities/settings" as *;

.locatie__header {
	background-color: $lichtpaars;
	min-height: 30vh;
	position: relative;
	z-index: 5;

	@media(min-width: $breakpoint-lg) {
		margin-bottom: 75px;
	}
	
	&.locatie__header--kind-inschrijven {
		background: $groen;
		
		h1, p, span {
			color: white;
		}
	}
	
	h1 {
		font-size: 2em;
		margin-bottom: -0px;
		padding-left: 15px;
		padding-top: 40vh;
		
		@media(min-width: $breakpoint-lg) {
			font-size: 3.333em;
			margin-bottom: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
	
	&--image {
		background-color: black;
		// border-radius: 250px 0 0 250px / 50% 0 0 50%;
		border-radius: 0px 0px 0px 25%;
		height: 70%;
		left: 10%;
		overflow: hidden;
		padding-top: 0;
		position: absolute;
		top: 0px;
		width: 90%;
		
		img {
			height: 100%;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			transform: translate(0%, 0%);
			width: 100%;
		}
		
		@media(min-width: $breakpoint-lg) {
			height: calc(100% + 80px);
			left: calc(50% + 50px);
			width: 650px;
			// border-radius: 200px 0 0 200px / 50% 0 0 50%;
		}
		
		@media(min-width: $breakpoint-xl) {
			left: auto;
			right: 0;
			// border-radius: 250px 0 0 250px / 50% 0 0 50%;
		}

		.video__container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// border-radius: 0px 50px 0px 0px;
			overflow: hidden;
			margin-top: 0;

			video {
				border-radius: 0;
			}
		}
	}
}

.locatie__intro {
	padding-bottom: 0;
	* {
		color: $paars;
		font-family: $heading-font;
		font-size: 1.05em;
	}
}

.locatie__info {
	// padding-bottom: 0;
	position: relative;
	
	// @media(max-width: $breakpoint-lg) {
	// 	.row {
	// 		flex-direction: column-reverse;
	// 	}
	// }
	
	&--image {
		align-self: flex-end;
		background-color: black;
		border-radius: 0px 50px 0px 0px;
		left: -15px;
		overflow: hidden;
		padding-top: 75%;
		position: relative;
		width: calc(100% + 30px);
		
		img {
			height: 100%;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			transform: translate(0%, 0%);
			width: 100%;
		}
		
		@media(min-width: $breakpoint-lg) {
			border-radius: 50px 50px 0px 0px;
			left: -150px;
			width: calc(100% + 125px);
		}

		.video__container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 0px 50px 0px 0px;
			overflow: hidden;
			margin-top: 0;

			video {
				border-radius: 0;
			}

			@media(min-width: $breakpoint-lg) {
				border-radius: 50px 50px 0px 0px;
			}
		}
	}
	
	.info_column {
		padding: 25px 0px;

		&.groen {
			background-color: $lichtgroen;
			padding: 25px;
			border-radius: 9px;

			table td {
				border-color: rgba(0,0,0,0.2);
				padding: 15px 0px;
			}
		}
		
		small {
			display: inline-block;
			margin-top: 15px;
		}
		
		p a {
			display: block;
			border-bottom: none;
		}
	}

	.buttons {
		padding: 0 0;
	}

	.ook_locaties {
		h3 {
			// margin-bottom: 15px;
		}
		li {
			line-height: 1.5;
			list-style: none;
			
			a {
				&::before {
					color: $lichtpaars;
					content: "\f111";
					display: inline-block;
					font-family: "Font Awesome 5 Free";
					font-size: 1.3em;
					-webkit-font-smoothing: antialiased;
					font-style: normal;
					font-variant: normal;
					font-weight: 900;
					margin-right: 8px;
					position: relative;
					top: 2px;
					text-align: right;
					text-rendering: auto;
					content: "\f30b";
				}
			}
		}
	}
	
	.openingstijden {
		width: 100%;
		
		.dag {
			width: 50%;
		}
	}
}

.locatie__tabs {
	background-color: $lichtgeel;
	overflow: hidden;
	
	.tabs {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		width: 100%;
		overflow: hidden;
		margin-bottom: 0;
		
		li {
			float: left;
			padding: 0 0 10px 0;
			width: calc(50% - 2.5px);
			list-style: none;
			line-height: 1;
			
			&:nth-of-type(odd) {
				padding-right: 5px;
			}
		}
		
		@media(min-width: $breakpoint-lg) {
			align-items: stretch;
			flex-wrap: nowrap;
			overflow-x: auto;
			
			li {
				padding: 0;
				width: auto;
			}
		}
		
		.tab, .tab-blank {
			background-color: #f2f2f2;
			border-radius: 15px 15px 15px 15px;
			color: $groen;
			cursor: pointer;
			display: inline-block;
			font-family: $heading-font;
			font-size: 1.1em;
			margin-right: 25px;
			padding: 15px 25px;
			text-align: center;
			text-decoration: none;
			width: 100%;
			opacity: 1;
			height:100%;
			min-height: 100%;
			
			@media(min-width: $breakpoint-lg) {
				border-radius: 15px 15px 0px 0px;
				display: block;
				padding: 27px 40px;
				padding-bottom: 22px;
				width: auto;
			}
			
			&:hover {
				background-color: $lichtgroen;
				opacity: 1;
			}
			
			&.active {
				background-color: white;
			}
		}
	}
	
	.tab-items {
		background-color: white;
		border-radius: 0px 0px 25px 0px;
		display: block;
		min-height: 500px;
		padding: 25px;
		width: 100%;
		
		@media(min-width: $breakpoint-lg) {
			padding: 50px;
		}
		
		.tab-item {
			display: none;
			margin-bottom: 100px;
			width: 100%;
			// padding: 0px 25px;
			
			@media(min-width: $breakpoint-lg) {
				padding: 0px 50px;
			}
			
			.tab-title {
				color: #666;
				font-weight: 600;
			}
			
			&.active {
				display: block;
			}
		}
	}
	
	.animatie-wrapper .beer {
		bottom: -50px;
		left: -150px;
		position: absolute;
		// width: 80%;
		
		@media(min-width: $breakpoint-md) {
			// width: auto;
		}
	}
	
	.tekening2 {
		display: none;
		position: absolute;
		right: -60px;
		top: 80px;
		
		@media(min-width: $breakpoint-lg) {
			display: block;
		}
	}
}

.single-locaties {
	
	.team-slider {
		width: 100%;
		
		&__item {
			display: table;
			outline: none;
			padding: 25px;
			text-align: center;
			width: 25%;
			
			figure {
				background-color: #ccc;
				border-radius: 50%;
				height: 0px;
				padding-top: 100%;
				position: relative;
				transform: translate(0, 0);
				width: 100%;
				
				
				img {
					height: 100%;
					left: 0;
					object-fit: cover;
					position: absolute;
					top: 0;
					transform: translate(0, 0);
					width: 100%;
				}
			}
			
			h3 {
				margin-top: 15px;
			}
			
			p {
				font-size: 0.8em;
			}
		}
	}
	
	.downloads {
		width: 100%;
		
		.download {
			background-color: $lichtgroen;
			border-radius: 3px;
			color: $paars;
			display: flex;
			flex-direction: row;
			font-weight: 600;
			padding: 15px 20px;
			text-decoration: none;
			transition: all 0.2s ease-in-out;
			width: 100%;
			
			&:hover {
				background-color: $groen;
				color: white;
			}
			
			.fa {
				color: inherit;
				margin-left: auto;
			}
		}
	}
}

.slick-dots {
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    justify-content: center;
    align-items: center;
	flex-wrap: wrap;

    li {
        font-size: 0;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background-color: #F4F4F4;
        margin: 0px 5px;

        &.slick-active {
            background-color: $groen;
        }
    }

    > li:only-child {
        display: none;
    }
}