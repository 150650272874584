@use "../utilities/settings" as *;

.text-2col-golf {
    background: $lichtgroen;
    padding-bottom: 300px;
    overflow: hidden;

    .tekening {
        position: absolute;
        bottom: -5px;
        right: 0px;
        max-width: 100%;
        max-height: 300px;

        @media(min-width: $breakpoint-sm) {
            right: auto;
            left: 10px;
        }

        @media(min-width: $breakpoint-lg) {
            right: 50px;
            left: auto;
        }
    }
    
    .golf {
        width: 100%;
        bottom: -1px;
        height: 100px;
        position: absolute;
        background-image: url(../img/golf-shape.svg);
        background-size: auto 100%;
        background-repeat: repeat-x;
    }

    .column-count {
	    padding: 0 15px;
	    
        @media(min-width: $breakpoint-lg) {
            column-count: 2;
            column-gap: 50px;
        }
    }
}