.tekening {
	position: absolute;
}

.pattern {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(../img/pattern/zebra.svg);
	background-size: 300px;
	background-repeat: repeat;
	top: 0;
	left: 0;
	opacity: 0.04;

	&.zebra {
		background-image: url(../img/pattern/zebra.svg);
	}

	&.regen {
		background-image: url(../img/pattern/regen.svg);
	}

	&.honing {
		background-image: url(../img/pattern/honing.svg);
	}

	&.stippen {
		background-image: url(../img/pattern/stippen.svg);
		opacity: 0.05;
		background-size: 250px;
	}

	&.ruis {
		background-image: url(../img/pattern/ruis.svg);
		background-size: 1250px;
		opacity: 0.1;
	}

	&.reflected {
		transform: scaleX(-1);
	}
}