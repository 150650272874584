@use "../../utilities/settings" as *;

.wist-je-dat {
	position: relative;
	overflow: hidden;
	margin-top: 30px;
	
	img {
		width: 100%;
	}
	
	.nummer {
		width: 48px;
		height: 48px;
		background: $roze;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		font-family: 'vag_rundschrift_dregular', sans-serif;
		position: absolute;
		cursor: pointer;
		transition: .15s ease-out;
		transform-origin: center center;
		
		// everything 10% smaller per breakpoint
		
		@media (min-width: $breakpoint-sm) {
			font-size: 28px;
			width: 56px;
			height: 56px;
		}
		@media (min-width: $breakpoint-md) {
			font-size: 36px;
			width: 64px;
			height: 64px;
		}
		@media (min-width: $breakpoint-lg) {
			width: 72px;
			height: 72px;
			font-size: 40px;
		}
		@media (min-width: $breakpoint-xl) {
			width: 80px;
			height: 80px;
			font-size: 45px;
		}
		
		span {
			color: white;
			position: relative;
			z-index: 3;
		}
		
		&::before {
			content: '';
			position: absolute;
			left: -12.5%;
			top: -12.5%;
			background: rgba($roze, .5);
			width: 60px;
			height: 60px;
			z-index: 2;
			border-radius: 50%;
			transition: .15s ease-out;
			transform-origin: center center;
			
			@media (min-width: $breakpoint-sm) {
				height: 70px;
				width: 70px;
			}
			@media (min-width: $breakpoint-md) {
				width: 80px;
				height: 80px;
			}
			@media (min-width: $breakpoint-lg) {
				width: 90px;
				height: 90px;
			}
			@media (min-width: $breakpoint-xl) {
				width: 100px;
				height: 100px;
			}
		}
		
		&:hover {
			transition: .15s ease-out;
			transform: scale(.9);
			
			&::before {
				transform: scale(1.2);
				transition: .15s ease-out;
			}
		}
		
	}
	
	.nummer1 {
		top: 10px;
		left: 15%;
		@media (min-width: $breakpoint-sm) {
			top: 20px;
			left: 90px;
		}
		@media (min-width: $breakpoint-md) {
			top: 30px;
			left: 120px;
		}
		@media (min-width: $breakpoint-lg) {
			top: 40px;
			left: 160px;
		}
		@media (min-width: $breakpoint-xl) {
			top: 50px;
			left: 180px;
		}
	}
	
	.nummer2 {
		top: 15px;
		right: 24%;
		@media (min-width: $breakpoint-sm) {
			top: 15px;
			right: 120px;
		}
		@media (min-width: $breakpoint-md) {
			top: 30px;
			right: 160px;
		}
		@media (min-width: $breakpoint-lg) {
			right: 210px;
		}
		@media (min-width: $breakpoint-xl) {
			top: 50px;
			right: 280px;
		}
	}
	
	.nummer3 {
		top: 30px;
		right: 4%;
		@media (min-width: $breakpoint-sm) {
			top: 70px;
			right: 20px;
		}
		@media (min-width: $breakpoint-md) {
			top: 100px;
			right: 40px;
		}
		@media (min-width: $breakpoint-lg) {
			top: 140px;
			right: 60px;
		}
		@media (min-width: $breakpoint-xl) {
			top: 180px;
			right: 80px;
		}
	}
	
	.nummer4 {
		bottom: 25px;
		right: 40%;
		@media (min-width: $breakpoint-sm) {
			right: 215px;
			bottom: 50px;
		}
		@media (min-width: $breakpoint-md) {
			bottom: 90px;
			right: 280px;
		}
		@media (min-width: $breakpoint-lg) {
			bottom: 135px;
			right: 390px;
		}
		@media (min-width: $breakpoint-xl) {
			bottom: 150px;
			right: 450px;
		}
	}
	
	.nummer5 {
		left: 8%;
		bottom: 20px;
		@media (min-width: $breakpoint-sm) {
			left: 60px;
			bottom: 50px;
		}
		@media (min-width: $breakpoint-md) {
			left: 85px;
			bottom: 70px;
		}
		@media (min-width: $breakpoint-lg) {
			left: 120px;
		}
		@media (min-width: $breakpoint-xl) {
			left: 150px;
			bottom: 100px;
		}
	}
	
	.toelichting {
		//position: absolute;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		top: 5px;
		left: 5px;
		text-align: center;
		align-items: center;
		justify-content: center;
		background: white;
		position: absolute;
		transform: scale(0);
		z-index: 5;
		box-shadow: 0 0 22px 0 rgba(0, 0, 0, .1);
		display: flex;
		transition: .1s ease-out;
		opacity: 0;
		
		&.active {
			transform: scale(1);
			transition: .15s ease-in;
			opacity: 1;
		}
		
		> .close {
			position: absolute;
			right: -5px;
			top: -5px;
			font-size: 16px;
			transition: .15s ease-out;
			cursor: pointer;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: $groen;
			transform: rotate(0);
			
			@media (min-width: $breakpoint-md) {
				right: 20px;
				top: 20px;
			}
			
			i {
				color: white;
				font-size: 36px;
			}
			
			&:hover {
				background: darken($groen, 10%);
				transition: .15s ease-out;
				transform: rotate(90deg);
			}
		}
		
		@media (min-width: $breakpoint-md) {
			width: 340px;
			height: 340px;
			border-radius: 50%;
			box-shadow: 0 0 22px 0 rgba(0, 0, 0, .1);
		}
	}
	
	@media (min-width: $breakpoint-md) {
		.toelichting1 {
			right: auto;
			left: 10px;
			top: 10px;
			bottom: auto;
		}
		.toelichting2 {
			left: auto;
			right: 100px;
			top: 10px;
			bottom: auto;
		}
		.toelichting3 {
			left: auto;
			right: 50px;
			top: 80px;
			bottom: auto;
		}
		.toelichting4 {
			right: auto;
			left: 300px;
			bottom: 10px;
			top: auto;
		}
		.toelichting5 {
			right: auto;
			left: 10px;
			bottom: 10px;
			top: auto;
		}
	}
	
	@media (min-width: $breakpoint-lg) {
		.toelichting2 {
			right: 180px;
		}
		.toelichting3 {
			right: 20px;
		}
		.toelichting4 {
			left: 440px;
		}
	}
	
	.toelichting-tekst {
		padding: 10px;
		
		> .titel {
			margin: 0 0 15px 0;
			font-size: 1.3em;
			color: $roze;
		}
		
		@media (min-width: $breakpoint-md) {
			padding: 20px;
		}
	}
}