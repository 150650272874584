@use "../utilities/settings" as *;

section.blog-magazines {
	background-color: lighten($lichtbruin, 5%);
	padding: 50px 0 175px;
	
	h2 {
		color: $groen;
	}

	&__related {
		background-color: white;
		background-image: url(../img/related.svg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
	}
	
	.col__blog {
		margin-top: 30px;
		
		&:nth-child(1) {
			.card-blog {
				figure {
					@media (min-width: $breakpoint-md) {
						padding-top: 48%;
					}
				}
				
				.card-blog__content {
					@media (min-width: $breakpoint-md) {
						max-width: 270px;
					}
					
					@media (min-width: $breakpoint-lg) {
						max-width: 230px;
					}
					
					@media (min-width: $breakpoint-xl) {
						max-width: 290px;
					}
				}
			}
		}
	}
	
	.card-blog {
		border-radius: 15px;
		display: block;
		overflow: hidden;
		position: relative;
		
		&:hover {
			.card-blog__content {
				transform: translateY(0);
				transition: transform .2s ease-out;
			}
		}
		
		figure {
			padding-top: 100%;
		}
	}
	
	.card-blog__content {
		background: white;
		border-radius: 10px 10px 0 0;
		bottom: 0;
		left: 30px;
		padding: 20px;
		position: absolute;
		transform: translateY(41px);
		transition: transform .2s ease-out;
		width: calc(100% - 60px);
		
		.read-more {
			color: $groen;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.card-blog__category {
		font-family: Roboto, sans-serif;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 12px;
		color: $paars;
		margin-bottom: 7.5px;
	}
	
	.card-blog__title {
		margin-bottom: 15px;
	}
	
	.btn {
		margin-top: 50px;
	}
	
	@media (max-width: $breakpoint-xs) {
		.col__blog {
			margin-top: 15px;
		}
		
		.card-blog__content {
			background: transparent;
			border-radius: 0;
			display: flex;
			flex-flow: column wrap;
			height: 100%;
			justify-content: center;
			max-width: 100%;
			padding: 0;
			position: static;
			transform: translateY(0);
			width: 100%;
		}
		
		.card-blog__title {
			font-size: 14px;
		}
		
		figure {
			border-radius: 8px;
			height: 100%;
			padding-top: 100%;
		}
	}
}