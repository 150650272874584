@use "../utilities/settings" as *;

.formulierpagina-heading {
	background: $groen;
	margin-bottom: 200px;
	min-height: 280px;
	position: relative;
	
	@media (min-width: $breakpoint-lg) {
		margin-bottom: 50px;
		min-height: 30vh;
	}
	
	
	h1, p, span {
		color: white;
	}
	
	figure.formulierpagina-heading__image {
		border-radius: 120px 0 0 120px / 50% 0 0 50%;
		height: 220px;
		padding-top: 0;
		position: absolute;
		right: 0;
		top: 260px;
		width: 70%;
		
		@media (min-width: $breakpoint-lg) {
			border-radius: 200px 0 0 200px / 50% 0 0 50%;
			height: calc(100% + 150px);
			padding-bottom: 0;
			position: absolute;
			top: -50px;
			width: 40%;
		}
		
		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
	
}


.wolken--1 {
	right: 0px;
	top: -50px;
	width: 90px;
	height: 90px;
	
	@media (min-width: $breakpoint-lg) {
		right: 50px;
		top: -90px;
		width: 250px;
		height: 250px;
	}
}

.wolken--2 {
	left: 30px;
	top: 0;
	width: 90px;
	height: 90px;
	
	@media (min-width: $breakpoint-lg) {
		left: 100px;
		top: -30px;
		width: 250px;
		height: 250px;
	}
}

.slang {
	left: 0;
	top: 30%;
	width: 150px;
	height: 150px;
	
	@media (min-width: $breakpoint-xl) {
		width: 330px;
		height: 330px;
	}
}

.plantjes {
	bottom: 10px;
	right: 20%;
	width: 180px;
	
	@media (min-width: $breakpoint-md) {
		right: calc(50% - 300px);
	}
}

.aanvullende-informatie {
	display: flex;
	justify-content: center;
	flex-flow: column wrap;
	
	h2 {
		margin-bottom: 30px;
		text-align: center;
	}
	
	.btn {
		align-self: center;
	}
}

.succes-bericht {
	margin-bottom: 0;
	text-align: center;
	color: $groen;
	
	&::after {
		border: 2px solid $groen;
		content: '';
		position: absolute;
		height: calc(100% + 30px);
		width: 100%;
		background: rgba($groen, .15);
		left: 0;
		z-index: -1;
		top: -15px;
	}
}

#controleren ul {
	list-style-type: none;
}