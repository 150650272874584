@use "../utilities/settings" as *;

#text-image-leone-bruin {
	background-color: $lichtbruin;
}

#text-image-leone-roze {
	background-color: $roze;
}

section.text-image-leone {
	
	.text-image__text {
		display: flex;
		text-align: left;
		flex-flow: column wrap;
		height: 100%;
		justify-content: flex-end;
		padding: 0 0 0px 0;

		&.column {
			flex-flow: column;
		} d
		
		//@media (min-width: $breakpoint-xl) {
		//	margin-top: 50px;
		//}
		
		h2, p {
			width: 100%;
		}
		
		h2 {
			margin-bottom: 30px;
		}
		
		.btn {
			position: relative;
			margin-top: 15px;
		}
	}
	@media(max-width: $breakpoint-sm) {
		.animatie-wrapper .beer {
			display: none;
		}
	}




	@media(max-width: $breakpoint-md) {
		.animatie-wrapper .hond {
			position: absolute;
			left:80%;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}

	@media(min-width: $breakpoint-xl) {

		.animatie-wrapper .beer {
			right: 0px;
			transform:scaleX(-1);
			left: unset; 
		}
	
	}
	


	
	
	.text-image__image {
		border-radius: 90px 0 0 0;
		bottom: 0;
		height: 100%;
		max-height: 450px;
		object-fit: cover;
		object-position: right center;
		right: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			max-height: 100%;
			max-width: 100%;
			position: static;
		}
		
		@media (min-width: $breakpoint-xl) {
			padding: 0 15px;
			position: static;
		}
	}
	
	.bij {
		bottom: 250px;
		height: 115px;
		left: calc(50% - 224px);
		width: 109px;
		z-index: 2;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% - 134px);
		}
		
		@media (min-width: $breakpoint-lg) {
			bottom: -50px;
			left: calc(50% - 350px);
		}
	}
	
	// .planten {
	// 	bottom: -28px;
	// 	left: calc(50% - 190px);
	// 	width: 150px;
		
		
	// 	@media (min-width: $breakpoint-md) {
	// 		bottom: -15px;
	// 		left: calc(50% - 280px);
	// 		width: 260px;
	// 	}
		
	// 	@media (min-width: $breakpoint-lg) {
	// 		left: calc(50% - 150px);
	// 	}
		
	// 	@media (min-width: $breakpoint-xl) {
	// 		left: calc(50% - 120px);
	// 	}
		
	// }

	// .bosje {
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: calc(50% - 70px);
	// 	z-index: 2;
	// }

	.planten {
		position: absolute;
		bottom: -1px;
		left: 10px;
		width: 150px;
		height: 100px;

		.plant-animatie {
			width: 150px;
			height: 150px;
			position: absolute;
			bottom: -10px;
			left: -40px;
		}

		.bosje {
			position: absolute;
			left: 0px;
			bottom: 0;
			width: 100%;
			z-index: 2;
		}

		@media(min-width: $breakpoint-lg) {
			width: 250px;
			height: 200px;
			left: 50%;

			.plant-animatie{
				width: 200px;
				height: 200px;
			}
		}
	}
}