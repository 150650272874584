@use "../utilities/settings" as *;

section.text-image-golf-geel {
	background: #F3DD7D;
	margin-top: 50px;
	margin-top: 100px;
	position: relative;
	z-index: 2;

	.fish {
		position: absolute;
		top: -80px;
		left: 15%;
		z-index: -2;
		width: 120px;
		display: none;


		@media(min-width: $breakpoint-sm) {
			display: block;
		}
	}

	.yellow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #F3DD7D;
	}

	h2 {
		color: $oranje;
	}

	.text-image__text {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: center;
		padding: 0 0 50px 0;
		
		@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
			padding-bottom: 100px;
		}
		
		h2, p {
			width: 100%;
		}
		
		h2 {
			margin-bottom: 30px;
		}
		
		.btn {
			margin-top: 15px;
		}
	}

	.btn {
		background-color: $oranje;

		&:hover {
			background-color: darken($oranje, 10%);
		}
	}

	.row-reverse {
		.text-image__image {
			border-radius: 0px 90px 0 0;
		}
	}

	.text-image__image {
		border-radius: 90px 0 0 0;
		bottom: 0;
		height: 100%;
		max-height: 450px;
		aspect-ratio: 4 / 3;
		object-fit: cover;
		object-position: center center;
		right: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			max-height: 100%;
			position: static;
		}
		
		@media (min-width: $breakpoint-xl) {
			position: static;
		}
	}

	
	
	.ruimtevaarder {
		bottom: -30px;
		left: 10%;
		width: 80%;
		height: auto;
		max-height: 95%;
		object-fit: contain;
		position: relative;
		z-index: 2;
		
		@media (min-width: $breakpoint-lg) {
			right: calc(50% - 595px);
			bottom: -55px;
			width: 100%;
			left: 0;
		}

		@media (min-width: $breakpoint-lg) {
			bottom: -80px;
		}
	}
	
	.golf {
		background-image: url(../img/golf-geel.svg);
		background-repeat: repeat-x;
		background-size: auto 100%;
		bottom: calc(100% - 1px);
		left: 0;
		height: 100px;
		width: 100%;
		z-index: 2;
	}
}