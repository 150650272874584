@use "../utilities/settings" as *;

.page-toestemmingsformulier-inbakeren {
	background: #EEF2F8;
	
	.toestemmingsformulier {
		overflow-x: hidden;
	}
	
	.verklaring {
		font-size: .9em;
		margin-top: 30px;
		position: relative;
		
		label {
			cursor: pointer;
			line-height: 1.5;
			padding-left: 13px;
			
			&::before {
				color: #DFE6F0;
				content: "\f0c8";
				display: inline-block;
				font-family: 'Font Awesome 5 Free';
				font-size: 1.6em;
				font-weight: 400;
				left: 0;
				position: absolute;
				top: -.26em;
			}
		}
		
		input[type=checkbox] {
			visibility: hidden;
			
			&:checked ~ label {
				&::before {
					color: $paars;
					content: "\f14a";
					font-size: 1.6em;
				}
			}
		}
		
		ul {
			margin-left: 30px;
			
			li {
				line-height: 1.5;
				list-style: none;
				padding-left: 20px;
				position: relative;
				
				&::before {
					color: $paars;
					content: "\f111";
					display: inline-block;
					font-family: 'Font Awesome 5 Free';
					font-size: .6em;
					font-weight: 700;
					left: 0;
					position: absolute;
					top: .6em;
				}
			}
		}
	}
	
	p {
		font-size: .9em;
		margin: 25px 0;
	}
	
	.plantjes {
		bottom: 10px;
		right: 10px;
		width: 215px;
		
		@media (min-width: $breakpoint-md) {
			left: calc(50% + 180px);
			right: auto;
			bottom: 20px;
		}
	}
}
