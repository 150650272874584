@use "../utilities/settings" as *;

#breadcrumbs {
	* {
		color: $paars;
		font-size: 16px;
	}
	
	a, a:hover {
		border-bottom: 0;
	}
}