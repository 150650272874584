@use "../utilities/settings" as *;

.bedankt {
	height: 312px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow: hidden;
	
	@media (min-width: $breakpoint-md) {
		height: 450px;
	}
	
	h1 {
		text-shadow: 0 0 6px $roze;
	}
	
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: 66% 50%;
		
		@media (min-width: $breakpoint-md) {
			object-position: 50% 50%;
		}
	}
	
	.tekening {
		width: 95px;
		right: 15%;
		bottom: -2px;
		
		@media (min-width: $breakpoint-md) {
			width: 160px;
			right: calc(50% - 300px);
		}
	}
	
	.magazines-slider .pauw {
		height: 45%;
	}
}

.bedanktpagina-section {
	.btn {
		margin-top: 15px;
	}
}