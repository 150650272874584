@use "../../utilities/settings" as *;

.fotodagboek {
	margin-top: 150px;
	
	&::before {
		content: '';
		position: absolute;
		width: 100px;
		height: 55px;
		left: calc(50% - 50px);
		top: -27px;
		background-image: url(../img/fotodagboek-clipboard.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 100%;
		z-index: 2;
		
		@media (min-width: $breakpoint-lg) {
			width: 190px;
			height: 105px;
			left: calc(50% - 95px);
			top: -50px;
		}
	}
	
	.container {
		width: 100vw !important;
		max-width: 1400px !important;
		padding-left: 0;
		padding-right: 0;
		margin-left: -5px;
		
		@media (min-width: $breakpoint-lg) {
			margin-left: auto;
		}
		
		@media (min-width: 1400px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	
	.ringband {
		position: relative;
		
		&::before {
			content: '';
			position: absolute;
			width: 20px;
			height: calc(100% + 1px);
			left: 10px;
			top: -1px;
			z-index: 2;
			overflow-y: hidden;
			background-image: url(../img/ringband.svg);
			background-size: 100% auto;
			background-repeat: repeat-y;
			background-position: 0px -1px;
			
			@media (min-width: $breakpoint-lg) {
				width: 45px;
			}
		}
	}
	
	.grid-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		
		&:nth-of-type(odd) {
			> .grid-item.-tekst {
				order: 2
			}
			
			> .grid-item.-image {
				order: 1
			}
			
			@media (min-width: $breakpoint-lg) {
				> .grid-item.-tekst {
					order: 1;
					padding-left: 100px;
				}
				> .grid-item.-image {
					order: 2
				}
			}
		}
		
		&:nth-of-type(even) {
			> .grid-item.-tekst {
				order: 2
			}
			
			> .grid-item.-image {
				order: 1
			}
		}
		
		&:nth-of-type(3n) {
			.-tekst {
				background-color: $lichtgrijs;
				
				> .titel {
					color: $groen;
				}
				
				.pattern {
					background-image: url(../img/pattern/ruis.svg);
					background-size: 2000px;
					opacity: 0.1;
				}
			}
		}
		
		&:nth-of-type(3n-1) {
			.-tekst {
				background-color: $lichtbruin;
				
				> .titel {
					color: $paars;
				}
				
				.pattern {
					background-image: url(../img/pattern/regen.svg);
					background-size: 150px;
					opacity: .04;
				}
			}
		}
		
		&:nth-of-type(3n-2) {
			.-tekst {
				background-color: $oranje;
				color: white;
				
				> .titel {
					color: white;
				}
				
				.pattern {
					background-image: url(../img/pattern/stippen.svg);
					background-size: 450px;
					opacity: .03;
				}
			}
		}
	}
	
	.grid-item {
		width: 100%;
		color: black;
		padding: 30px;
		flex-shrink: 0;
		word-break: break-word;
		hyphens: auto;
		position: relative;
		overflow: hidden;
		aspect-ratio: 4/3;
		
		@media (min-width: $breakpoint-lg) {
			aspect-ratio: 7/5;
			width: 50%;
			padding: 60px;
			order: 2;
		}
		
		> .titel {
			margin-bottom: 15px;
			margin-top: 0;
		}
		
		> img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
	}
	
	.grid-item > figure {
	
	}
}

.fotodagboek-header {
	padding: 0 30px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	aspect-ratio: 16/7;
	
	@media (min-width: $breakpoint-lg) {
		padding: 0 60px;
	}
	
	> .background {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	> .titel {
		color: #fff;
		position: relative;
		z-index: 1;
		margin: 0;
		text-shadow: 0 0 10px $zwart;
	}
}

.fotodagboek-intro {
	display: flex;
	flex-direction: column;
	padding: 75px 30px;
	background-color: #f6f3ef;
	background-image: url(../img/pattern/lijnen.svg);
	background-size: 100% auto;
	background-repeat: repeat-y;
	
	@media (min-width: $breakpoint-lg) {
		padding: 150px 40px;
	}
	
	> .titel {
		width: 100%;
		max-width: 450px;
		margin: 0 auto 1em auto;
	}
	
	> .tekst {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		font-weight: bold;
	}
}