@use "settings" as *;

body {
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	
	@media(min-width: $breakpoint-md) {
		font-size: 18px;
	}
}

h1, h2, h3, h4, h5, h6, blockquote {
	font-family: $heading-font;
	color: $paars;
	margin: 0;
	padding: 0;
	
	&.center {
		text-align: center;
		width: 100%;
	}
}

h1 {
	font-size: 2.14em;
	margin-bottom: 25px;
}

blockquote {
	padding-left: 25px;
	position: relative;
	
	&::before {
		position: absolute;
		content: '';
		height: 100%;
		border-left: 5px solid currentColor;
		left: 0;
		top: 0;
	}
}

blockquote, blockquote p {
	margin-top: 15px;
	color: $groen;
	font-size: 1.1em;
	margin-bottom: 15px;
}

h2 {
	font-size: 1.78em;
	margin-bottom: 15px;
}

p {
	line-height: 1.5;
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

ol {
	list-style-position: inside;
	
	li {
		line-height: 1.5;
	}
}

ul {
	list-style-type: disc;
	list-style-position: inside;
	
	li {
		line-height: 1.5;
		
		a {
			&:hover {
				opacity: .75;
			}
		}
	}
}

a {
	text-decoration: none;
	border: none;
}

.text {
	&-center {
		text-align: center;
	}
	
	&-light {
	}
	
	&-small {
		font-size: smaller;
	}
	
	&-white {
		color: white;
		
		* {
			color: white;
		}
	}
}

//Bigger fonts op home
.home {
	@media(min-width: $breakpoint-md) {
		h1 {
			font-size: 4.7em;
		}
		
		blockquote {
			font-size: 3.05em;
		}
		
		h2 {
			font-size: 3.6em;
			
			&.h2-small {
				font-size: 2.8em;
			}
		}
	}
}

//Tables default opmaak
table {
	width: 100%;
	max-width: 600px;
	
	th, td {
		padding: 10px 0px;
		
		&.streep {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	th {
		text-align: left;
		border-bottom: 1px solid $paars;
		color: $paars;
		font-weight: normal;
		font-family: $button-font;
	}
	
	td {
		border-bottom: 1px dashed #ccc;
	}
}

.section {
	p a:not(.btn) {
		text-decoration: none;
		border-bottom: 2px solid currentColor;
		
		&:hover {
			// text-decoration: underline;
			// color: darken(inherit, 10%);
			color: $groen;
			border-bottom-color: currentColor;
		}
	}
	
	&.footer p a:hover {
		color: white;
		opacity: .75;
	}
}

.small {
	color: $grijs;
	font-size: smaller;
	margin: 0;
}

.single-post, .single-magazines {
	h2, h3 {
		margin-top: 40px;
	}
	
	blockquote {
		position: relative;
		padding-left: 0;
		margin: 30px 0;
		
		p {
			font-size: 35px;
			color: $roze;
			line-height: 43px;
			padding-top: 75px;
			padding-left: 0;
			margin: 0;
		}
		
		&::before {
			content: '';
			background-image: url('../img/quote.svg');
			width: 80px;
			height: 65px;
			position: absolute;
			left: 0;
			top: 0;
			bordeR: 0;
		}
	}
}

figcaption {
	font-size: 0;
	line-height: 0;
}

::selection {
	background: $groen;
	color: white;
}