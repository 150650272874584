@use "../utilities/settings" as *;

#melding {
	padding: 10px 20px;
	text-align: center;
	position: fixed;
	background: $paars;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	
	* {
		color: white !important;
	}
	
	a {
		text-decoration: underline;
	}
	
	.close {
		position: absolute;
		right: 0;
		top: 0;
		float: right;
		cursor: pointer;
		transition: .2s ease-out;
		height: 100%;
		padding: 0 10px;
		transform-origin: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		
		i {
			line-height: 31px;
			font-size: 16px;
			color: white;
		}
		
		&:hover {
			transform: scale(1.33);
			transition: .2s ease-out;
		}
	}
	
	p {
		color: white;
		font-size: 14px;
		margin: 0;
		
		&:empty {
			display: none;
		}
	}
}