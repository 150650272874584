@use "../utilities/settings" as *;

footer {
	background-color: $groen;
	padding-top: 50px;
	position: relative;
	z-index: 3;
	
	.footer__golf {
		background-image: url(../img/golf-shape-footer.svg);
		background-position: center center;
		background-repeat: repeat-x;
		background-size: auto 100%;
		height: 100px;
		top: -99px;
		width: 100%;
	}
	
	.footer {
		* {
			color: white;
		}

		p a:not(.btn) {
			border-bottom: none;
		}
		
		.footer__col {
			margin-bottom: 30px;
			
			@media (min-width: $breakpoint-lg) {
				margin-bottom: 0;
			}
			
			&:nth-of-type(1) {
				order: 3;
			}
			
			&:nth-of-type(2) {
				order: 4;
			}
			
			&:nth-of-type(3) {
				order: 1;
			}
			
			&:nth-of-type(4) {
				order: 2;
			}
			
			@media (min-width: $breakpoint-md) {
				&:nth-of-type(1) {
					order: 1;
				}
				
				&:nth-of-type(2) {
					order: 2;
				}
				
				&:nth-of-type(3) {
					order: 3;
				}
				
				&:nth-of-type(4) {
					order: 4;
				}
			}
			
			hr.footer__line {
				border: 0;
				border-top: 2px solid darken($groen, 10%);
				margin: 25px 0 0;
				width: 100%;
				opacity: .5;
				
				@media (min-width: $breakpoint-md) {
					margin-top: 50px
				}
			}
		}
		
		.footer__logo {
			max-width: 100%;
			width: 194px;
			height: 67.22px;
			
			@media (min-width: $breakpoint-lg) {
				width: 228px;
				height: 78px;
			}
		}
		
		hr {
			border: 0;
			border-top: 1px solid rgba(255, 255, 255, 0.1);
			margin: 0 0 30px 0;
			width: 100%;
			
			@media (min-width: $breakpoint-md) {
				margin-top: 50px
			}
		}
		
		h3 {
			font-size: 16px;
			line-height: 1.5;
		}
		
		p, a {
			border-bottom: 0;
			color: white;
			font-size: 13px;
			margin-top: 11px;
			
			@media (min-width: $breakpoint-md) {
				margin-top: 5px;
			}
		}
		
		
		ul {
			list-style: none;
			
			li {
				font-size: 13px;
				padding: 11px 0;
				
				a {
					font-size: inherit;
					margin-top: 0;
				}
			}
		}
		
		.icon__social {
			margin-right: 10px;
			width: 40px;
			height: 40px;
		}
		
		.footer__slide-out {
			h3 {
				cursor: pointer;
				
				@media (min-width: $breakpoint-md) {
					cursor: auto;
				}
				
				span {
					float: right;
					opacity: .5;
					transition: transform .2s ease-out;
					
					&.js-rotate {
						filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
						-moz-transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						transition: transform .2s ease-out;
					}
				}
			}
			
			.footer__slide-out-menu {
				display: none;
				
				@media (min-width: $breakpoint-md) {
					display: block !important;
				}
			}
		}
		
		.footer__copyright {
			
			p, a {
				color: rgba(255, 255, 255, 0.4);
			}
		}
	}
}