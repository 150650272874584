@use "../../utilities/settings" as *;

.paarse-achtergrond {
	background: $paars;
	overflow: visible;
	margin-top: 50px;
	
	.alien-blauw {
		position: absolute;
		right: 100px;
		z-index: -1;
		width: 80px;
		top: -80px;
		height: 80px;
		
		@media (min-width: $breakpoint-md) {
			width: 100px;
			top: -100px;
			height: 100px;
			right: 150px;
		}
		
		@media (min-width: $breakpoint-lg) {
			right: 15%;
			width: 130px;
			top: -130px;
			height: 130px;
		}
	}
	
	* {
		color: white;
	}
	
	> .titel {
		margin-top: 0 !important;
	}
}