@use "../../utilities/settings" as *;

section.text-image__round-blog {
    position: relative;

    &.large-padding {
        padding-bottom: 50px;
        margin-bottom: 150px;
    }

    .col-lg-6 {
        position: static;
    }

    .row-reverse .boom {
        position: absolute;
        right: 15%;
        width: 200px;
        bottom: -200px;
        height: 200px;

        @media (min-width: $breakpoint-md) {
            bottom: -4px;
        }

        @media (min-width: $breakpoint-lg) {
            bottom: -150px;
        }

        @media (min-width: $breakpoint-xl) {
            bottom: -76px;
            right: 30%;
        }
    }
    
    .boom {
        position: absolute;
        right: 15%;
        width: 200px;
        bottom: -200px;
        height: 200px;

        @media (min-width: $breakpoint-md) {
            bottom: 0px;
        }

        @media (min-width: $breakpoint-lg) {
            bottom: -145px;
        }

        @media (min-width: $breakpoint-xl) {
            bottom: -70px;
            right: 30%;
        }
    }

    .image-placeholder {
        width: 100%;
        height: 0;
        padding-top: 75%;
        display: none;

        @media (min-width: $breakpoint-lg) {
            display: block;
        }
    }

    .text-image__image--container {
        position: relative;
        margin-top: 50px;

        .text-image__image {
            width: 100%;
            aspect-ratio: 4 / 3;
            height: auto;
            display: block;
            border-radius: 40px;
        }

        @media (min-width: $breakpoint-lg) {
            position: absolute;
            top: 50px;
            right: 0;
            height: calc(100% - 100px);
            width: calc(50% - 50px);
            margin-top: 0;

            .text-image__image {
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0px;
                left: 0;
                border-radius: 17% 0px 0px 17% / 50% 0 0 50%;
            }
        }
    }

    .row-reverse .text-image__image--container {
        .boom {
            bottom: -3px;
        }
        @media (min-width: $breakpoint-lg) {
            left: 0;
            right: auto;

            .boom {
                left: auto;
                right: 20px;
                max-width: 150px;
                width: 150px;
                height: 150px;
                bottom: -4px;
            }

            .text-image__image {
                border-radius: 0px 17% 17% 0px / 0% 50% 50% 0%;
            }
        }

        @media (min-width: $breakpoint-xl) {
            .boom {
                width: 200px;
                height: 200px;
                right: 0px;
                bottom: -4px;
            }
        }
    }

    .text-image__image--container {
        .show-md {
            position: absolute;
            bottom: 0px;
            left: 97%;

            @media (min-width: $breakpoint-lg) {
                bottom: 145px;
                left: 45%;
            }

            @media (min-width: $breakpoint-xl) {
                bottom: 70px;
                left: 35%;
            }
        }
    }

    .flexbox-center {
        &.row-reverse {
            .show-md {
                position: absolute;
                bottom: 0px;
                left: 97%;

                @media (min-width: $breakpoint-lg) {
                    bottom: 0;
                    left: 45%;
                }

                @media (min-width: $breakpoint-xl) {
                    bottom: 0;
                    left: 35%;
                }
            }
        }
    }
}
