@use "../utilities/settings" as *;

.single-post > .breadcrumbs {
	z-index: 2;
}

.blog-header {
	> .background {
		background-color: rgba(#C8DCD1, .3);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin-top: -100px;
		
		@media (min-width: $breakpoint-md) {
			margin-top: -200px;
		}
	}
	
	> .pattern {
		background-color: $grijs;
		margin-top: -100px;
		background-size: 2250px;
		
		@media (min-width: $breakpoint-md) {
			margin-top: -200px;
		}
	}
	
	.alien-blauw {
		position: absolute;
		height: 100px;
		width: auto;
		top: -100px;
		right: 0;
	}
	
	.boom-met-ogen {
		position: absolute;
		bottom: 0;
		height: 250px;
		left: 15px;
	}
}

.post-thumbnail-container {
	position: relative;
	
	&.-versie3 {
		margin-top: 100px;
		
		@media (min-width: $breakpoint-md) {
			margin-top: 90px;
		}
	}
	
	.animatie-lijn {
		.bij {
			bottom: -60px;
			height: 100px;
			left: -25px;
			width: 70px;
			z-index: 2;
			
			@media (min-width: $breakpoint-md) {
				width: 150px;
				height: 150px;
				bottom: -170px;
				left: -76px;
			}
		}
		
		.planten {
			bottom: 0;
			left: 0;
			overflow: hidden;
			width: 80px;
			height: 72px;
			transform: scaleX(-1);
			
			.plant-animatie {
				width: 120px;
				height: 72px;
			}
			
			@media (min-width: $breakpoint-md) {
				width: 160px;
				height: 144px;
				
				.plant-animatie {
					width: 216px;
					height: 100px;
					z-index: 2;
				}
			}
		}
		
	}
	
	.boompje {
		position: absolute;
		left: -30px;
		width: 65px;
		height: 65px;
		z-index: 1;
		bottom: 0;
		
		@media (min-width: $breakpoint-md) {
			left: -20px;
			width: 200px;
			height: 200px;
		}
	}
	
	.wolken {
		right: -15px;
		top: -30px;
		width: 125px;
		height: 70px;
		
		@media (min-width: $breakpoint-lg) {
			right: -50px;
			top: -50px;
			width: 240px;
			height: 133px;
		}
	}
	
	.oranje-ij {
		width: 80px;
		height: 80px;
		top: -40px;
		right: -40px;
		position: absolute;
		
		@media (min-width: $breakpoint-md) {
			right: -62px;
			top: -62px;
			height: 125px;
			width: 125px;
		}
	}
}

.post__thumbnail {
	position: relative;
	width: 100vw;
	left: 50%;
	transform: translate(-50%, 0);
	padding-top: 45%;
	
	@media (min-width: $breakpoint-md) {
		position: static;
		width: auto;
		left: auto;
		transform: translate(0, 0);
		border-radius: 0 0 80px 0;
	}
}

.post__meta {
	font-size: .9em;
	margin-top: 5px;
	
	span, sep {
		color: $paars;
	}
	
	.post__author {
		font-weight: 700;
	}
}

.blogoverview-link {
	display: block;
	margin-bottom: 30px;
	color: $paars;
	font-weight: bold;
	
	i {
		color: $paars;
	}
	
	&:hover {
		color: lighten($paars, 15%);
		
		i {
			transform: translateX(-6px);
			transition: .15s ease-out;
		}
	}
	
	i {
		transition: .15s ease-out;
		margin-right: 10px;
	}
}

.post-introductie {
	> .bg-color {
		background-color: $lichtbruin;
		height: calc(100% + 200px);
		margin-top: -200px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: -1;
	}
	
	> .pattern {
		height: calc(100% + 200px);
		margin-top: -200px;
		z-index: -1;
	}
	
	figure {
		height: 0;
		position: relative;
		padding-top: 100%;
		overflow: hidden;
		border-radius: 40px 40px 40px 0;
		
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	.slang {
		left: auto;
		right: 0;
		z-index: 5;
		transform: scale(-1);
		top: 35%;
		
		@media (min-width: $breakpoint-md) {
			top: 15%;
		}
	}
	
	
}


.post__content {
	padding: 30px 0;
}

.post__overzicht-knop {
	flex-flow: row wrap;
	margin-top: 30px;
	
	.btn {
		margin: 5px;
		
		&.btn-primary {
			@media (max-width: $breakpoint-md) {
				order: 2;
			}
		}
		
		&.btn-secondary {
			@media (max-width: $breakpoint-md) {
				order: 1;
			}
		}
	}
}

.single-post {
	.text-2col {
		h2 {
			color: $roze;
		}
	}
	
	.matchtest {
		padding-bottom: 150px;
		overflow: visible;
		margin-top: 50px;
		
		@media (min-width: $breakpoint-xxl) {
			padding-bottom: 100px;
		}
		
		.alien-blauw {
			position: absolute;
			right: 30px;
			width: auto;
			z-index: -1;
			top: -75px;
			height: 75px;
			
			@media (min-width: $breakpoint-lg) {
				right: 15%;
			}
		}
		
		
		.animatie-wrapper .boom {
			bottom: 0;
			top: auto;
			right: auto;
			left: 30px;
			width: 250px;
			height: 210px;
			
			@media (min-width: $breakpoint-xxl) {
				left: 5%;
			}
		}
	}
}