@use "../utilities/settings" as *;

.linkinbio {
	padding: 30px 0;
}

.linkinbio__card {
	margin-bottom: 30px;
	height: calc(100% - 30px);
	display: flex;
	
	figure {
		padding-top: 100%;
	}
	
	&:hover {
		.card__title {
			text-decoration: underline;
		}
	}
	
	.card__content {
		padding: 20px;
		
		.card__meta {
			font-size: 13px;
			margin-bottom: 5px;
			
			.card__date {
				font-family: Roboto, sans-serif;
				font-size: 1em;
				font-weight: 400;
			}
		}
	}
}