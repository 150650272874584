@use "../utilities/settings" as *;

.tax-locatie_categories, .page-villa-doomijn {
    .locatie__header {
        margin-bottom: 0;

        &--image {
            @media(min-width: $breakpoint-lg) {
                // height: calc(100% + 120px);
            }
        }
    }

    .search-location {
        padding-top: 120px;
    }
}